import { Flex, Text } from '@chakra-ui/react';
import { SimulationResult } from '../../../types/SimulationResult';
import { formatNumber } from '../../../utils/formatters';

interface EmissionsSummaryProps {
  simulations: SimulationResult[];
}

export default function EmissionsSummary(props: EmissionsSummaryProps) {
  const { simulations } = props;

  function getTotal() {
    if (simulations.length > 0) {
      let total = simulations.map((sim) => {
        return +sim.calculated_values.co2_emissions_annual;
      });

      return total.reduce((a, b) => {
        return a + b;
      }, 0);
    } else {
      return 0;
    }
  }

  return (
    <Flex justifyContent={'center'} alignItems={'center'} direction={'column'}>
      <Text fontSize={'3xl'} fontWeight={'bold'} mt={10}>
        {formatNumber(getTotal())} kg CO<sub>2</sub>
      </Text>
      <Text fontSize={'xl'}>Total project emissions</Text>
    </Flex>
  );
}
