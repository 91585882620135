import { SimpleGrid, AspectRatio, Radio, Stack } from '@chakra-ui/react';
import { Box, ButtonGroup } from '@chakra-ui/react';
import { Formik } from 'formik';
import { InputControl, RadioGroupControl, ResetButton, SelectControl, SubmitButton, TextareaControl } from 'formik-chakra-ui';
import * as Yup from 'yup';
import Map from './components/Map';
import { getSeaScenario } from '../../../firestore/simulations';
import { useState } from 'react';
import OneColumnLayout from '../../layout/OneColLayout';
import OpsProfileChart from './components/graphs/OpsProfileChart';
import { useLocation, useNavigate } from 'react-router-dom';
import vessels from './inputs/vessels.json';
import opsProfiles from './inputs/opsprofiles.json';
import { BreadCrumbInfo } from '../../../types/BreadCrumbInfo';
import { SeaScenarioRequest } from '../../../types/SeaScenarioRequest';

interface ScenarioState {
  projectID: string;
  encodedPath?: string;
}

export default function SeaTransportScenario() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { projectID, encodedPath } = state as ScenarioState;
  const breadCrumbs: BreadCrumbInfo[] = [
    { to: '/projects', displayText: 'Projects' },
    { to: '/project', state: { projectID: projectID }, displayText: 'Project overview' },
  ];

  const [mapKey, setMapKey] = useState<number>(1); //hack to force map to re-render on reset
  const [path, setPath] = useState<string | undefined>(encodedPath);

  const onSubmit = async (values: any) => {
    if (!path) return; // TODO: show user user the error - must set path in map.
    let request: SeaScenarioRequest = {
      speed: values.speed,
      distance: values.distance,
      vessel_id: values.vessel_type,
      ops_profile_id: values.opsProfileID,
      name: values.scenarioName,
      notes: values.notes,
      project_id: projectID,
      encoded_path: path,
    };
    let runResult = (await getSeaScenario(request)) as any;
    if (runResult.result === 'error') {
      console.error(runResult);
    } else {
      navigate('/sea-transport-result', {
        state: { ...runResult },
      });
    }
  };

  const initialValues = {
    speed: '14',
    distance: '',
    vessel_type: '40',
    scenarioName: '',
    opsProfileID: '2',
    notes: '',
  };

  const validationSchema = Yup.object({
    scenarioName: Yup.string().required('You need to provide a scenario name'),
    speed: Yup.number().typeError('Speed must be a number').required('Speed must be a number'),
    distance: Yup.number().typeError('Distance must be a number').required('Complete the distance by drawing the route in the map'),
  });

  function newRoute(route: number, path: string, setFieldValue: any) {
    setFieldValue('distance', route.toFixed(2));
    setPath(path);
  }

  function handleReset(_values: any, _formProps: any) {
    setMapKey(Math.round(Math.random() * 100)); //change to update state & force re-render of map .
  }

  return (
    <OneColumnLayout
      sectionLabel='Create Scenario'
      sectionHeading='New Sea Transport scenario'
      sectionIntro='Create a single vessel, single route scenario. Click in the map to set waypoints in order to measure and locate the route, click twice on the endpoint to complete the route. To draw a new route, just start drawing and the old route will be removed once you have completed the new route.'
      breadCrumbs={breadCrumbs}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width='full'>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} onReset={handleReset}>
          {({ handleSubmit, values, errors, setFieldValue }) => (
            <>
              <AspectRatio ratio={16 / 9}>
                <Map onRouteChange={(routeLength, encodedPath) => newRoute(routeLength, encodedPath, setFieldValue)} key={mapKey} encodedPath={encodedPath} />
              </AspectRatio>
              <Box borderWidth='1px' rounded='lg' shadow='1px 1px 3px rgba(0,0,0,0.3)' p={6} m='10px auto' as='form' onSubmit={handleSubmit as any}>
                <InputControl name='scenarioName' label='Scenario Name' />

                <InputControl name='distance' label='Distance (nautical miles)' mt={4} isDisabled={true} />
                <InputControl name='speed' label='Transit speed (knots)' mt={4} />

                <SelectControl name='vessel_type' label='Vessel type' mt={4}>
                  {vessels.vessels.map((vessel: { id: number; type: string }) => {
                    return (
                      <option value={vessel.id} key={vessel.id}>
                        {vessel.type}
                      </option>
                    );
                  })}
                </SelectControl>

                <RadioGroupControl name='opsProfileID' label='Usage pattern' mt={4}>
                  <Stack>
                    {opsProfiles.profiles.map((profile) => {
                      return (
                        <Radio value={profile.id + ''} key={profile.id}>
                          {profile.name}
                          <OpsProfileChart speedProfile={profile.speeds} />
                        </Radio>
                      );
                    })}
                  </Stack>
                </RadioGroupControl>
                <TextareaControl name='notes' label='Additional notes' helperText='Optional - if you need to note down something about this scenario.' mt={4} />

                <ButtonGroup mt={4}>
                  <SubmitButton loadingText='Calculating.. this could take some time'>Run Scenario</SubmitButton>
                  <ResetButton>Reset</ResetButton>
                </ButtonGroup>
              </Box>
            </>
          )}
        </Formik>
      </SimpleGrid>
    </OneColumnLayout>
  );
}
