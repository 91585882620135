import { Table, TableContainer, Tbody, Td, Tr, Thead, Th, Box, Heading, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SimulationResult } from '../../../../types/SimulationResult';
import { formatNumber } from '../../../../utils/formatters';
import { TCOcalculations } from './TCOcalculations';

interface TCOParameterProps {
  simulationResult: SimulationResult;
  onUpdate: any;
}
let TCOinstance: TCOcalculations;

export default function TCO(props: TCOParameterProps) {
  const { fast_charge_min_kw } = props.simulationResult.result_summary;
  const [initialPurchaseCost] = useState<number>(props.simulationResult.result_summary.write_off_avg_yr);

  const vehicle = JSON.parse(props.simulationResult.inputs.vehicle);

  const isElectric = vehicle && vehicle.drivetrain.toLowerCase() === 'electric';

  const [TCO_values, setTCOValues] = useState<any>(new TCOcalculations(props.simulationResult));

  useEffect(() => {
    TCOinstance = new TCOcalculations(props.simulationResult);
    update();
    //just run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    km_year,
    fast_charge_cost,
    slow_charge_cost,
    total_fuel_need,
    write_off_avg_yr,
    insurance_price_yr,
    fuel_price_unit,
    total_fuel_cost,
    total_annual_cost,
    total_charging_need_kWh,
    total_maintenance,
    trips_per_month,
    fast_charge_percentage,
    total_co2_emissions,
  } = TCO_values;

  function update() {
    props.onUpdate(TCOinstance.get_TCO_values());
    setTCOValues(TCOinstance);
  }

  function onTripsChange(value: number) {
    TCOinstance.trips_per_month = value;
    update();
  }

  function onMixChange(value: number) {
    TCOinstance.fast_charge_percentage = value;
    update();
  }

  function onFuelCostChange(value: number) {
    TCOinstance.fuel_price_unit = value;
    update();
  }

  function onPurchaseCostChange(value: number) {
    let adjustedCost = (initialPurchaseCost * (value + 100)) / 100;
    TCOinstance.write_off_avg_yr = adjustedCost;
    update();
  }

  const color = isElectric ? 'teal' : 'orange';

  return (
    <>
      <Heading size={'sm'}>Trips per month:</Heading>
      <Box>
        <Slider aria-label='trips-slider' defaultValue={trips_per_month} onChange={(v: number) => onTripsChange(v)} min={1} max={200} maxW={500}>
          <SliderTrack bg={`${color}.200`}>
            <SliderFilledTrack bg={`${color}.400`} />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Text>
          {formatNumber(trips_per_month)} trips per month totalling <b>{formatNumber(km_year)} km</b> a year and{' '}
          {isElectric && (
            <>
              a total energy consumption of <b>{formatNumber(total_charging_need_kWh)} kWh </b>
            </>
          )}
          {!isElectric && (
            <>
              a total fuel consumption of <b>{formatNumber(total_fuel_need / 1000)} l </b>
            </>
          )}
        </Text>
      </Box>
      {isElectric && (
        <>
          <Heading size={'sm'} mt={5}>
            Charging mix:
          </Heading>
          <Box>
            <Slider aria-label='mix-slider' defaultValue={fast_charge_percentage} onChange={(v: number) => onMixChange(v)} min={0} max={100} maxW={500}>
              <SliderTrack bg={`${color}.200`}>
                <SliderFilledTrack bg={`${color}.400`} />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Text>
              <b>{fast_charge_percentage} %</b> of charging needs covered by fast charging.
            </Text>
          </Box>
        </>
      )}
      {!isElectric && (
        <>
          <Heading size={'sm'} mt={5}>
            Fuel cost:
          </Heading>
          <Box>
            <Slider
              aria-label='mix-slider'
              defaultValue={fuel_price_unit}
              onChange={(v: number) => onFuelCostChange(v)}
              min={5}
              max={100}
              step={0.1}
              maxW={500}
            >
              <SliderTrack bg={`${color}.200`}>
                <SliderFilledTrack bg={`${color}.400`} />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Text>
              <b>{fuel_price_unit}</b> kr in fuel cost per litre.
            </Text>
          </Box>
        </>
      )}

      <TableContainer>
        <Table variant='striped' colorScheme={color} mt={4}>
          <Thead>
            <Tr>
              <Th>Total cost of ownership:</Th>
              <Th isNumeric>Annual</Th>
              <Th isNumeric>Per km</Th>
              <Th isNumeric>Adjust</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Maintenance cost:</Td>
              <Td isNumeric>{formatNumber(total_maintenance)} kr</Td>
              <Td isNumeric>{formatNumber(total_maintenance / km_year)} kr</Td>
              <Td />
            </Tr>
            <Tr>
              <Td>Annualised purchase cost:</Td>
              <Td isNumeric>{formatNumber(write_off_avg_yr)} kr</Td>
              <Td isNumeric>{formatNumber(write_off_avg_yr / km_year)} kr</Td>
              <Td>
                <Box>
                  <Slider aria-label='purchase-cost-slider' defaultValue={0} onChange={(v: number) => onPurchaseCostChange(v)} min={-50} max={50} maxW={500}>
                    <SliderTrack bg={`${color}.200`}>
                      <SliderFilledTrack bg={`${color}.400`} />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </Box>
              </Td>
            </Tr>
            <Tr>
              <Td>Insurance cost:</Td>
              <Td isNumeric>{formatNumber(insurance_price_yr)} kr</Td>
              <Td isNumeric>{formatNumber(insurance_price_yr / km_year)} kr</Td>
              <Td />
            </Tr>
            {isElectric && (
              <>
                <Tr>
                  <Td>Fast charging (from {formatNumber(fast_charge_min_kw)} kw/minute)</Td>
                  <Td isNumeric>{formatNumber(fast_charge_cost)} kr</Td>
                  <Td isNumeric>{formatNumber(fast_charge_cost / km_year)} kr</Td>
                  <Td />
                </Tr>
                <Tr>
                  <Td>Slow charging (up to {formatNumber(fast_charge_min_kw)} kw/minute)</Td>
                  <Td isNumeric>{formatNumber(slow_charge_cost)} kr</Td>
                  <Td isNumeric>{formatNumber(slow_charge_cost / km_year)} kr</Td>
                  <Td />
                </Tr>
              </>
            )}
            {!isElectric && (
              <>
                <Tr>
                  <Td>Fuel cost:</Td>
                  <Td isNumeric>{formatNumber(total_fuel_cost)} kr</Td>
                  <Td isNumeric>{formatNumber(total_fuel_cost / km_year)} kr</Td>
                  <Td />
                </Tr>
              </>
            )}
            <Tr fontWeight={'bold'}>
              <Td>Total:</Td>
              <Td isNumeric>{formatNumber(total_annual_cost)} kr</Td>
              <Td isNumeric>{formatNumber(total_annual_cost / km_year)} kr</Td>
              <Td />
            </Tr>
          </Tbody>
        </Table>
        <Table variant={'striped'} colorScheme={color} mt={6} mb={10}>
          <Thead>
            <Tr>
              <Th>Total footprint:</Th>
              <Th isNumeric>Annual</Th>
              <Th isNumeric>Per km</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                CO<sub>2</sub> emissions:
              </Td>
              <Td isNumeric>{formatNumber(total_co2_emissions)} tonnes</Td>
              <Td isNumeric>{formatNumber(total_co2_emissions / km_year)} kg</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
