import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SimulationResult } from '../../../types/SimulationResult';
import { formatCapitalFirst, formatNumber } from '../../../utils/formatters';
import { TCOcalculations } from '../sea/components/TCOcalculations';

interface ScenarioInfoSeaProps {
  seaScenarios: SimulationResult[];
  activeScenarios: any[];
}

export default function ScenarioInfoSea(props: ScenarioInfoSeaProps) {
  const [seaScenarios, setSeaScenarios] = useState<SimulationResult[]>([]);
  const [activeScenarios, setActiveScenarios] = useState<any>([]);

  useEffect(() => {
    setActiveScenarios(props.activeScenarios);
    setSeaScenarios(props.seaScenarios);
  }, [props]);

  return (
    <Table variant={'striped'} colorScheme={'teal'} mt={4}>
      <Thead>
        <Tr>
          <Th>Scenario Name</Th>
          <Th isNumeric>Route length (nm)</Th>
          <Th isNumeric>Duration (min)</Th>
          <Th isNumeric>
            C0<sub>2</sub> emissions (kg)
          </Th>
          <Th isNumeric>Fuel consumption (l)</Th>
          <Th isNumeric>Energy consumption (kWh)</Th>
          <Th isNumeric>Transit speed</Th>
          <Th isNumeric>Vessel type</Th>

          <Th isNumeric>Energy efficiency (kWh / NM)</Th>
          <Th isNumeric>Fuel efficiency (l/NM)</Th>

          <Th isNumeric>Trips per month</Th>
          <Th isNumeric>NM per year</Th>
          <Th isNumeric>Maintenance cost (year)</Th>
          <Th isNumeric>Annualised purchase cost</Th>
          <Th isNumeric>Insurance</Th>
          <Th isNumeric>Total Fuel cost (kr)</Th>
          <Th isNumeric>Annual TCO (kr)</Th>
          <Th isNumeric>Cost per NM (kr)</Th>
          <Th isNumeric>
            Annual C0<sub>2</sub> emissions (tonnes)
          </Th>
          <Th isNumeric>
            C0<sub>2</sub> emissions per Nm (kg)
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {activeScenarios.map((as: any) => {
          if (!as.active) return null;
          let sim = seaScenarios[seaScenarios.findIndex((sim) => sim.id === as.id)];
          if (!sim) return null;
          let tco = new TCOcalculations(sim);
          return (
            <Tr key={sim.id}>
              <Td fontWeight={'bold'}>{sim.name}</Td>
              <Td isNumeric>{formatNumber(sim.inputs.distance)}</Td>
              <Td isNumeric>{formatNumber(sim.result_summary.duration_min)}</Td>
              <Td isNumeric>{formatNumber(sim.result_summary.co2_emissions_kg)}</Td>
              <Td isNumeric>{formatNumber(sim.result_summary.fuel_consumption_l)}</Td>
              <Td isNumeric>{formatNumber(sim.result_summary.consumed_energy_kw)}</Td>
              <Td isNumeric>{formatNumber(sim.result_summary.speed_knot)}</Td>

              <Td isNumeric>{formatCapitalFirst(sim.result_summary.ship.Vessel_type)}</Td>

              <Td isNumeric>{formatNumber(tco.total_consumed_energy / tco.nm_year)}</Td>
              <Td isNumeric>{formatNumber(tco.total_fuel_need / tco.nm_year)}</Td>
              <Td isNumeric>{formatNumber(tco.trips_per_month)}</Td>
              <Td isNumeric>{formatNumber(tco.nm_year)}</Td>
              <Td isNumeric>{formatNumber(tco.total_maintenance)}</Td>
              <Td isNumeric>{formatNumber(tco.write_off_avg_yr)} </Td>
              <Td isNumeric>{formatNumber(tco.insurance_kr_yr)} </Td>
              <Td isNumeric>{formatNumber(tco.total_fuel_cost)} </Td>
              <Td isNumeric fontWeight={'bold'}>
                {formatNumber(tco.total_annual_cost)}{' '}
              </Td>
              <Td isNumeric>{formatNumber(tco.total_annual_cost / tco.nm_year)} </Td>

              <Td isNumeric fontWeight={'bold'}>
                {formatNumber(tco.total_co2_emissions / 1000)}
              </Td>
              <Td isNumeric>{formatNumber(tco.total_co2_emissions / tco.nm_year)}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
