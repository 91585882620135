import { VStack, Image, Heading, Button, Text, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getProjects } from '../../firestore/projects';
import { auth } from '../../firestore/firebaseConfig';

import forecast from '../../assets/forecast.png';

export default function Forecast() {
  const [projects, setProjects] = useState<any>(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (user && !projects) {
      getProjects(user.uid)?.then((p) => setProjects(p));
    }
  }, [projects, user]);

  return (
    <>
      <VStack backgroundColor={useColorModeValue('gray.50', 'gray.900')} h='100%' w='100%' p={6}>
        <Heading size={'md'}>Forecast for planned projects</Heading>
        <Image src={forecast} />
        {projects && projects.length > 0 && (
          <>
            This graph is not real yet, but will eventually show the total forecast emissions from your projects for the next ten years!
            <Button as='a' href='/projects'>
              Go to my projects
            </Button>
          </>
        )}
        {(!projects || projects.length === 0) && (
          <>
            <Text>You have no projects yet, but if you did, you could see how your emissions would look like for the next 10 years here!</Text>
            <Button as='a' href='/project/create'>
              Create a project
            </Button>
          </>
        )}
      </VStack>
    </>
  );
}
