import { useEffect, useState } from 'react';
import { GraphData, GraphPoint } from '../../../../../types/TimeGraphData';
import AnnualGraph from '../../../../layout/graphs/AnnualGraph';

interface ProductionGraphProps {
  time_series: any;
}

export default function ProductionGraph(props: ProductionGraphProps) {
  const { time_series } = props;
  const [graphData, setGraphData] = useState<GraphData>();

  useEffect(() => {
    if (time_series) {
      let data: GraphPoint[] = [];

      for (const index in time_series.referenceTime) {
        let production = time_series.w[index];

        data.push({ x: +index / 24, y: production / 1000 });
      }
      setGraphData([
        {
          id: 'Production',
          color: 'teal',
          data: data,
        },
      ]);
    }
  }, [time_series]);

  return (
    <>
      {graphData && (
        <AnnualGraph legendX='Days' legendY='kW' data={graphData} heading={'Daily production'} description={'Typical daily production in a year'} />
      )}
    </>
  );
}
