import { SimpleGrid, AspectRatio, Image } from '@chakra-ui/react';
import { Box, ButtonGroup } from '@chakra-ui/react';
import { Formik } from 'formik';
import { InputControl, ResetButton, SelectControl, SubmitButton, TextareaControl } from 'formik-chakra-ui';
import * as Yup from 'yup';
import Map from './components/Map';
import buildings from './inputs/buildings.json';
import standards from './inputs/standards.json';
import { useState } from 'react';
import OneColumnLayout from '../../layout/OneColLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { BreadCrumbInfo } from '../../../types/BreadCrumbInfo';

import building from '../../../assets/office-simple.jpeg';
import { RoutePoint } from '../../../types/RoutePoint';
import { runBuildingScenario } from '../../../firestore/simulations';
import { BuildingScenarioRequest } from '../../../types/BuildingScenarioRequest';

interface ScenarioState {
  projectID: string;
}

export default function BuildingScenario() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { projectID } = state as ScenarioState;
  const breadCrumbs: BreadCrumbInfo[] = [
    { to: '/projects', displayText: 'Projects' },
    { to: '/project', state: { projectID: projectID }, displayText: 'Project overview' },
  ];

  const [mapKey, setMapKey] = useState<number>(1); //hack to force map to re-render on reset
  const [lat, setLat] = useState<number>(60.84);
  const [lng, setLng] = useState<number>(8.24);

  const onSubmit = async (values: any) => {
    let request: BuildingScenarioRequest = {
      building_type: values.building_type,
      footprint: values.footprint,
      floors: values.floors,
      floor_height: values.floor_height,
      tek_standard: values.tek_standard,
      target_temp: values.target_temp,
      lat,
      lng,
      notes: values.notes,
      name: values.scenarioName,
      project_id: projectID,
    };
    let runResult = await runBuildingScenario(request);
    //TODO: actually check if result is okay before redirecting.
    navigate('/building-result', {
      state: { ...runResult },
    });
  };

  const initialValues = {
    scenarioName: '',
    building_type: 'office_building',
    footprint: '',
    floors: '',
    floor_height: '',
    tek_standard: '1',
    target_temp: '',
    notes: '',
  };

  const validationSchema = Yup.object({
    scenarioName: Yup.string().required('You need to provide a scenario name'),
    footprint: Yup.number().typeError('Floor base area must be a number').required('Floor base area is required'),
    floors: Yup.number().typeError('Number of floors must be a number').required('Number of floors is required'),
    floor_height: Yup.number().typeError('Floor height must be a number').required('Floor height is required'),
    // target_temp: Yup.number().typeError('Target indoor temperature must be a number').required('Indoor temperature is required'),
  });

  function setPoint(point: RoutePoint) {
    setLat(point.latLng.lat());
    setLng(point.latLng.lng());
  }

  function handleReset(_values: any, _formProps: any) {
    setMapKey(Math.round(Math.random() * 100)); //change to update state & force re-render of map .
  }

  return (
    <OneColumnLayout
      sectionLabel='Create Scenario'
      sectionHeading='New Building scenario'
      sectionIntro='Create an energy consumption simulation for a new or existing building. Click in the map to set the building location and then fill in the form detailing building information.'
      breadCrumbs={breadCrumbs}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width='full'>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} onReset={handleReset}>
          {({ handleSubmit, values, errors, setFieldValue }) => (
            <>
              <Box>
                <Image src={building} />

                <AspectRatio ratio={16 / 9}>
                  <Map onSetPoint={(point) => setPoint(point)} key={mapKey} />
                </AspectRatio>
              </Box>
              <Box borderWidth='1px' rounded='lg' shadow='1px 1px 3px rgba(0,0,0,0.3)' p={6} m='10px auto' as='form' onSubmit={handleSubmit as any}>
                <InputControl name='scenarioName' label='Scenario Name' />
                <SelectControl name='building_type' label='Building type' mt={4}>
                  {buildings.buildings.map((b) => {
                    return (
                      <option value={b.type} key={b.id}>
                        {b.name}
                      </option>
                    );
                  })}
                </SelectControl>

                <InputControl name='footprint' label='Foot print / base area in m2' mt={4} />
                <InputControl name='floors' label='Number of floors' mt={4} />
                <InputControl name='floor_height' label='Average floor height' mt={4} />
                <SelectControl name='tek_standard' label='Build standard' mt={4}>
                  {standards.standards.map((standard: { id: number; type: string }) => {
                    return (
                      <option value={standard.id} key={standard.id}>
                        {standard.type}
                      </option>
                    );
                  })}
                </SelectControl>

                <InputControl name='target_temp' label='Target indoor temperature' mt={4} />

                <TextareaControl name='notes' label='Additional notes' helperText='Optional - if you need to note down something about this scenario.' mt={4} />

                <ButtonGroup mt={4}>
                  <SubmitButton loadingText='Calculating.. this could take some time'>Run Scenario</SubmitButton>
                  <ResetButton>Reset</ResetButton>
                </ButtonGroup>
              </Box>
            </>
          )}
        </Formik>
      </SimpleGrid>
    </OneColumnLayout>
  );
}
