import { Box, Heading, Text } from '@chakra-ui/react';
import { ResponsiveLine } from '@nivo/line';
import { useEffect, useState } from 'react';
import { GraphData } from '../../../types/TimeGraphData';
import { formatMinutes, formatNumber } from '../../../utils/formatters';

interface MinutesGraphProps {
  legendX: string;
  legendY: string;
  data: GraphData;
  heading: string;
  description: string;
}

export default function MinutesGraph(props: MinutesGraphProps) {
  const { legendX, legendY, data, description, heading } = props;

  const [tickValues, setTickValues] = useState<any>([]);
  const [minY, setMin] = useState<number>(0);

  useEffect(() => {
    if (data && data[0]?.data.length > 1) {
      let plotpoints = data[0].data;
      let ticks = [];

      let max = plotpoints[plotpoints.length - 1].x;
      let interval = Math.ceil(max / 15);
      for (var i = 0; i < max; i += interval) {
        ticks.push(i);
      }
      setTickValues(ticks);

      let min = plotpoints.reduce((prev, curr) => (prev.y < curr.y ? prev : curr));
      setMin(min.y - Math.abs(min.y / 5));
    }
  }, [data]);

  return (
    <Box h={400} mb={20} mt={10}>
      <Heading size={'md'}>{heading}</Heading>
      <Text>{description}</Text>
      {tickValues.length > 0 && (
        <ResponsiveLine
          data={data}
          margin={{ top: 20, right: 150, bottom: 50, left: 60 }}
          xScale={{ type: 'linear' }}
          yScale={{
            type: 'linear',
            min: minY,
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          yFormat=' >-.2f'
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: tickValues,
            legend: legendX,
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: legendY,
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          enablePoints={false}
          enableArea={true}
          areaBaselineValue={minY}
          enableGridX={false}
          useMesh={true}
          crosshairType='x'
          tooltip={({ point }) => {
            return (
              <div
                style={{
                  padding: '9px 12px',
                  border: '1px solid #ccc',
                  backgroundColor: 'rgba(255,255,255,.8)',
                }}
              >
                <div>
                  {legendY}: {formatNumber(point.data.y)}
                </div>
                <div>
                  {legendX}: {formatMinutes(point.data.x)}
                </div>
              </div>
            );
          }}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 10,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
            },
          ]}
        />
      )}
      {tickValues.length === 0 && (
        <Box width={'full'} display={'flex'} m={10}>
          <Heading size={'sm'}>No data</Heading>
        </Box>
      )}
    </Box>
  );
}
