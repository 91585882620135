import _ from 'lodash';
import { SimulationResult } from '../../../../types/SimulationResult';

export class TCOcalculations {
  trips_per_month: number;
  fuel_kr_unit: number;
  write_off_avg_yr: number;
  insurance_kr_yr: number;

  //privates
  #distance: number;
  #base_maintenance_kr_yr: number;
  #ongoing_maintenance_kr_nm: number;
  #co2_emissions_kg: number;
  #fuel_consumption_unit: number;
  #consumed_energy_kw: number;

  constructor(simulationResult: SimulationResult) {
    const { distance_nm, fuel_consumption_unit, co2_emissions_kg, consumed_energy_kw, energy_cost_kr } = simulationResult.result_summary;

    const { base_maintenance_kr_yr, ongoing_maintenance_kr_nm, insurance_kr_yr, write_off_avg_yr } = simulationResult.result_summary.ship;

    //tco has already been set
    const { total_cost_of_ownership } = simulationResult.calculated_values;
    if (!_.isEmpty(total_cost_of_ownership)) {
      this.trips_per_month = total_cost_of_ownership.trips_per_month;
      this.write_off_avg_yr = total_cost_of_ownership.write_off_avg_yr;
      this.fuel_kr_unit = total_cost_of_ownership.fuel_kr_unit;
      this.insurance_kr_yr = total_cost_of_ownership.insurance_kr_yr;
    } else {
      //no tco has been set yet, set defaults
      this.trips_per_month = 20;
      this.write_off_avg_yr = write_off_avg_yr;
      this.fuel_kr_unit = energy_cost_kr / fuel_consumption_unit;
      this.insurance_kr_yr = insurance_kr_yr;
    }

    this.#distance = distance_nm;
    this.#base_maintenance_kr_yr = base_maintenance_kr_yr;
    this.#consumed_energy_kw = consumed_energy_kw;

    this.#ongoing_maintenance_kr_nm = ongoing_maintenance_kr_nm;
    this.#co2_emissions_kg = co2_emissions_kg;
    this.#fuel_consumption_unit = fuel_consumption_unit;
  }

  get nm_year() {
    return this.#distance * this.trips_per_month * 12;
  }

  get total_maintenance() {
    return this.#base_maintenance_kr_yr + this.#ongoing_maintenance_kr_nm * this.nm_year;
  }

  get total_fuel_need() {
    return this.#fuel_consumption_unit * this.trips_per_month * 12;
  }

  get total_consumed_energy() {
    return this.#consumed_energy_kw * this.trips_per_month * 12;
  }

  get total_fuel_cost() {
    return this.total_fuel_need * this.fuel_kr_unit;
  }

  get total_co2_emissions() {
    return this.#co2_emissions_kg * this.trips_per_month * 12;
  }
  get total_annual_cost() {
    return this.total_maintenance + this.write_off_avg_yr + this.insurance_kr_yr + this.total_fuel_cost;
  }

  get_TCO_values = () => {
    let total_cost_of_ownership = {
      trips_per_month: this.trips_per_month,
      fuel_kr_unit: this.fuel_kr_unit,
      write_off_avg_yr: this.write_off_avg_yr,
      insurance_kr_yr: this.insurance_kr_yr,
      total_co2_emissions_ton: this.total_co2_emissions,
      total_consumed_energy_kwh: this.total_consumed_energy,
    };
    return total_cost_of_ownership;
  };
}
