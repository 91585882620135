import { useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface OpsProfileProps {
  speedProfile: number[];
}
export default function OpsProfileChart(props: OpsProfileProps) {
  const svgHeight = 80;
  const svgWidth = 200;
  const paddingLeft = 10;
  const paddingRight = 30;
  const paddingBottom = 20;

  const { speedProfile } = props;

  const svgRef = useRef(null);

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    const xScale = d3
      .scaleLinear()
      .domain([0, speedProfile.length - 1])
      .range([paddingLeft, svgWidth - paddingRight]);
    const highestYValue = Math.max(...speedProfile) + 4;
    const yScale = d3
      .scaleLinear()
      .domain([0, highestYValue])
      .range([svgHeight - paddingBottom, 0]);

    const myLine = d3
      .line()
      .x((value, index) => xScale(index))
      //@ts-ignore
      .y(yScale)
      .curve(d3.curveBasis);

    svg
      .selectAll('.line')
      .data([speedProfile])
      .join('path')
      .attr('class', 'line')
      // @ts-ignore
      .attr('d', myLine(speedProfile))
      .attr('fill', 'none')
      .attr('stroke', 'teal')
      .attr('stroke-width', '3')
      .attr('stroke-linecap', 'round');
  }, [speedProfile]);

  return (
    <svg width={svgWidth} height={svgHeight} ref={svgRef}>
      <g className='x-axis' />
      <g className='y-axis' />
    </svg>
  );
}

//useful info:
//https://codepen.io/slaleye/pen/BayGPzz?editors=0010
//https://blog.griddynamics.com/using-d3-js-with-react-js-an-8-step-comprehensive-manual/
