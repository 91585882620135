import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseDevConfig = {
  apiKey: 'AIzaSyBe5wK77QGxutOH-_OFuA6mphS_DwMXg14',
  authDomain: 'seidr-front-test.firebaseapp.com',
  projectId: 'seidr-front-test',
  storageBucket: 'seidr-front-test.appspot.com',
  messagingSenderId: '1033884015496',
  appId: '1:1033884015496:web:3cc715c7e29c4db049f83d',
  measurementId: 'G-6VLQXZH1FD',
};

const firebaseProdConfig = {
  apiKey: 'AIzaSyCOzszWz_lHSRcZIvCv11FWvyNSPv30gWA',
  authDomain: 'seidr-prod.firebaseapp.com',
  projectId: 'seidr-prod',
  storageBucket: 'seidr-prod.appspot.com',
  messagingSenderId: '1077494238365',
  appId: '1:1077494238365:web:34b403a6d63c711664d874',
  measurementId: 'G-23JMB4EC0G',
};

const app = process.env.REACT_APP_ENV === 'production' ? initializeApp(firebaseProdConfig) : initializeApp(firebaseDevConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);

//connectFunctionsEmulator(functions, 'localhost', 5001);
