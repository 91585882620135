import _ from 'lodash';
import { SimulationResult } from '../../../../types/SimulationResult';

export class TCOcalculations {
  trips_per_month: number;
  fast_charge_percentage: number;
  fuel_price_unit: number;
  write_off_avg_yr: number;
  insurance_price_yr: number;

  //privates
  #route_length_m: number;
  #base_maintenance_price_yr: number;
  #ongoing_maintenance_price_km: number;
  #charging_need_wh: number;
  #fast_charge_price_kwh: number;
  #slow_charge_price_kwh: number;
  #fuel_consumption_unit_10km: number;
  #emissions_co2_g: number;

  constructor(simulationResult: SimulationResult) {
    const {
      route_length_m,
      fuel_consumption_unit_10km,
      base_maintenance_price_yr,
      ongoing_maintenance_price_km,
      charging_need_wh,
      fast_charge_price_kwh,
      slow_charge_price_kwh,
      fuel_price_unit,
      write_off_avg_yr,
      insurance_price_yr,
      emissions_co2_g,
    } = simulationResult.result_summary;

    //tco has already been set
    const { total_cost_of_ownership } = simulationResult.calculated_values;
    if (!_.isEmpty(total_cost_of_ownership)) {
      this.trips_per_month = total_cost_of_ownership.trips_per_month;
      this.fast_charge_percentage = total_cost_of_ownership.fast_charge_percentage;
      this.write_off_avg_yr = total_cost_of_ownership.write_off_avg_yr;
      this.fuel_price_unit = total_cost_of_ownership.fuel_price_unit;
      this.insurance_price_yr = total_cost_of_ownership.insurance_price_yr;
    } else {
      //no tco has been set yet, set defaults
      this.trips_per_month = 20; //backwards compatibility
      this.fast_charge_percentage = 50; //backwards compatibility
      this.write_off_avg_yr = write_off_avg_yr;
      this.fuel_price_unit = fuel_price_unit;
      this.insurance_price_yr = insurance_price_yr;
    }

    this.#route_length_m = route_length_m;
    this.#base_maintenance_price_yr = base_maintenance_price_yr;
    this.#ongoing_maintenance_price_km = ongoing_maintenance_price_km;
    this.#charging_need_wh = charging_need_wh ? charging_need_wh : 0; //backwards compatibility
    this.#fast_charge_price_kwh = fast_charge_price_kwh;
    this.#slow_charge_price_kwh = slow_charge_price_kwh;
    this.#fuel_consumption_unit_10km = fuel_consumption_unit_10km;
    this.#emissions_co2_g = emissions_co2_g;
  }

  get km_year() {
    return (this.#route_length_m * this.trips_per_month * 12) / 1000;
  }

  get total_maintenance() {
    return this.#base_maintenance_price_yr + this.#ongoing_maintenance_price_km * this.km_year;
  }

  get total_charging_need_kWh() {
    return (this.#charging_need_wh * this.trips_per_month * 12) / 1000;
  }

  get fast_charge_cost() {
    return (this.total_charging_need_kWh * this.#fast_charge_price_kwh * this.fast_charge_percentage) / 100;
  }

  get slow_charge_cost() {
    return this.total_charging_need_kWh * this.#slow_charge_price_kwh * (1 - this.fast_charge_percentage / 100);
  }

  get total_fuel_need() {
    return this.#fuel_consumption_unit_10km * (this.km_year / 10);
  }

  get total_fuel_cost() {
    return (this.total_fuel_need / 1000) * this.fuel_price_unit;
  }

  get total_charge_cost() {
    return this.slow_charge_cost + this.fast_charge_cost;
  }

  get total_co2_emissions() {
    return (this.#emissions_co2_g / 1000) * this.trips_per_month * 12;
  }

  get total_annual_cost() {
    return this.total_maintenance + this.write_off_avg_yr + this.insurance_price_yr + this.total_fuel_cost + this.total_charge_cost;
  }

  get_TCO_values = () => {
    let total_cost_of_ownership = {
      trips_per_month: this.trips_per_month,
      fast_charge_percentage: this.fast_charge_percentage,
      fuel_price_unit: this.fuel_price_unit,
      write_off_avg_yr: this.write_off_avg_yr,
      insurance_price_yr: this.insurance_price_yr,
      total_co2_emissions_ton: this.total_co2_emissions,
      total_consumed_energy_kwh: this.total_charging_need_kWh,
    };
    return total_cost_of_ownership;
  };
}
