import {
  Table,
  TableCaption,
  Tbody,
  Td,
  Tr,
  Th,
  Thead,
  Slider,
  SliderFilledTrack,
  Text,
  SliderThumb,
  SliderTrack,
  Heading,
  Box,
  List,
  ListItem,
  TableContainer,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SimulationResult } from '../../../../types/SimulationResult';
import { formatNumber } from '../../../../utils/formatters';
import { TCOcalculations } from './TCOcalculations';

interface TCOProps {
  simulationResult: SimulationResult;
  onUpdate: any;
}

let TCOinstance: TCOcalculations;

export default function TCO(props: TCOProps) {
  const [TCO_values, setTCOValues] = useState<any>(new TCOcalculations(props.simulationResult));
  const [initialPurchaseCost] = useState<number>(props.simulationResult.result_summary.ship.write_off_avg_yr);

  useEffect(() => {
    TCOinstance = new TCOcalculations(props.simulationResult);
    update();
    //run once only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    trips_per_month,
    nm_year,
    total_fuel_need,
    total_consumed_energy,
    total_maintenance,
    write_off_avg_yr,
    total_fuel_cost,
    fuel_kr_unit,
    insurance_kr_yr,
    total_annual_cost,
    total_co2_emissions,
  } = TCO_values;

  function update() {
    props.onUpdate(TCOinstance.get_TCO_values());
    setTCOValues(TCOinstance);
  }

  function onTripsChange(value: number) {
    TCOinstance.trips_per_month = value;
    update();
  }

  function onFuelCostChange(value: number): any {
    TCOinstance.fuel_kr_unit = value;
    update();
  }

  function onPurchaseCostChange(value: number) {
    let adjustedCost = (initialPurchaseCost * (value + 100)) / 100;
    TCOinstance.write_off_avg_yr = adjustedCost;
    update();
  }

  let color = 'teal';

  return (
    <>
      <Heading size={'sm'}>Trips per month:</Heading>
      <Box>
        <Slider aria-label='trips-slider' defaultValue={trips_per_month} onChange={(v: number) => onTripsChange(v)} min={1} max={200} maxW={500}>
          <SliderTrack bg={`${color}.200`}>
            <SliderFilledTrack bg={`${color}.400`} />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Box>
          {formatNumber(trips_per_month)} trips per month totalling <b>{formatNumber(nm_year)} nm</b> a year and:
          <List>
            <ListItem>
              A total energy consumption of <b>{formatNumber(total_consumed_energy)} kWh </b>
            </ListItem>
            <ListItem>
              A total fuel consumption of <b>{formatNumber(total_fuel_need)} l </b>
            </ListItem>
          </List>
        </Box>
      </Box>

      <>
        <Heading size={'sm'} mt={5}>
          Fuel cost:
        </Heading>
        <Box>
          <Slider aria-label='mix-slider' defaultValue={fuel_kr_unit} onChange={(v: number) => onFuelCostChange(v)} min={5} max={100} step={0.1} maxW={500}>
            <SliderTrack bg={`${color}.200`}>
              <SliderFilledTrack bg={`${color}.400`} />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Text>
            <b>{formatNumber(fuel_kr_unit)}</b> kr in fuel cost per litre.
          </Text>
        </Box>
      </>

      <TableContainer>
        <Table variant='striped' colorScheme='teal' mt={4}>
          <TableCaption></TableCaption>
          <Thead>
            <Tr>
              <Th>Total cost of ownership:</Th>
              <Th isNumeric>Annual</Th>
              <Th isNumeric>Per NM</Th>
              <Th isNumeric>Adjust</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Maintenance cost:</Td>
              <Td isNumeric>{formatNumber(total_maintenance)} kr</Td>
              <Td isNumeric>{formatNumber(total_maintenance / nm_year)} kr</Td>
              <Td />
            </Tr>
            <Tr>
              <Td>Annualised purchase cost:</Td>
              <Td isNumeric>{formatNumber(write_off_avg_yr)} kr</Td>
              <Td isNumeric>{formatNumber(write_off_avg_yr / nm_year)} kr</Td>
              <Td>
                <Box>
                  <Slider aria-label='purchase-cost-slider' defaultValue={0} onChange={(v: number) => onPurchaseCostChange(v)} min={-50} max={50} maxW={500}>
                    <SliderTrack bg={`${color}.200`}>
                      <SliderFilledTrack bg={`${color}.400`} />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </Box>
              </Td>
            </Tr>
            <Tr>
              <Td>Insurance cost:</Td>
              <Td isNumeric>{formatNumber(insurance_kr_yr)} kr</Td>
              <Td isNumeric>{formatNumber(insurance_kr_yr / nm_year)} kr</Td>
              <Td />
            </Tr>
            <Tr>
              <Td>Fuel cost:</Td>
              <Td isNumeric>{formatNumber(total_fuel_cost)} kr</Td>
              <Td isNumeric>{formatNumber(total_fuel_cost / nm_year)} kr</Td>
              <Td />
            </Tr>
            <Tr fontWeight={'bold'}>
              <Td>Total:</Td>
              <Td isNumeric>{formatNumber(total_annual_cost)} kr</Td>
              <Td isNumeric>{formatNumber(total_annual_cost / nm_year)} kr</Td>
              <Td />
            </Tr>
          </Tbody>
        </Table>
        <Table variant={'striped'} colorScheme={color} mt={6} mb={10}>
          <Thead>
            <Tr>
              <Th>Total footprint:</Th>
              <Th isNumeric>Annual</Th>
              <Th isNumeric>Per NM</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                CO<sub>2</sub> emissions:
              </Td>
              <Td isNumeric>{formatNumber(total_co2_emissions)} tonnes</Td>
              <Td isNumeric>{formatNumber(total_co2_emissions / nm_year)} kg</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
