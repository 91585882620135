import { useEffect, useState } from 'react';
import { GraphData, GraphPoint } from '../../../../../types/TimeGraphData';
import MinutesGraph from '../../../../layout/graphs/MinutesGraph';

interface SpeedGraphProps {
  time_series: any;
}

export default function SpeedGraph(props: SpeedGraphProps) {
  const { time_series } = props;
  const [graphData, setGraphData] = useState<GraphData>();

  useEffect(() => {
    if (time_series) {
      let data: GraphPoint[] = [];
      let timestamp = 0;

      for (var index in time_series.duration_s) {
        timestamp += time_series.duration_s[index] / 60;
        let speed = time_series.speed_kmh[index];

        data.push({ x: timestamp, y: speed });
      }

      setGraphData([{ id: 'Vehicle speed', color: 'teal', data: data }]);
    }
  }, [time_series]);

  return (
    <>
      {graphData && (
        <MinutesGraph legendX='Minutes' legendY='Km/h' data={graphData} heading={'Speed en route'} description={'Normal vehicle speed during drive'} />
      )}
    </>
  );
}
