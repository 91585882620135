import { Center, Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import { Footer } from './Footer';

function LoginLayout(props: any) {
  return (
    <Grid minH='100vh' templateRows='8fr 50px'>
      <GridItem h={'100vh'}>
        <Center pt={'10vh'}>{props.children}</Center>
      </GridItem>
      <GridItem alignItems={'end'}>
        <Footer />
      </GridItem>
    </Grid>
  );
}

export default LoginLayout;
