import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { sendPasswordReset } from '../../firestore/authentication';
import { auth } from '../../firestore/firebaseConfig';
import { Input, VStack, Button, Link, Text, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import { Logo } from '../layout/Logo';
import LoginLayout from '../layout/LoginLayout';

function Reset() {
  const [email, setEmail] = useState('');
  const [user, loading] = useAuthState(auth);
  const [hasError, setError] = useState<boolean>();
  const [showResetconfirmation, setResetconfirmation] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) navigate('/projects');
  }, [user, loading, navigate]);

  const reset = () => {
    if (!email) return;
    sendPasswordReset(email)
      .then(() => {
        setError(false);
        setResetconfirmation(true);
      })
      .catch((error) => {
        console.log(error);
        switch (error.code) {
          case 'auth/invalid-email':
            setErrorMessage('The email you are trying to use is not a valid email address.');
            break;
          case 'auth/user-not-found':
            setErrorMessage("We don't seem to have an account for this email");
            break;
          default:
            setErrorMessage('Something went wrong during password reset. If the problem persists, please contact us.');
            break;
        }
        setError(true);
      });
  };

  return (
    <LoginLayout>
      <VStack maxW='md'>
        <Logo h='40vmin' pointerEvents='none' />
        <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='E-mail Address' />
        <Button onClick={reset}>Send password reset email</Button>
        <Text>
          Don't have an account?{' '}
          <Link href='/register' color='teal.500'>
            Register
          </Link>{' '}
          now.
        </Text>
        {hasError && (
          <Alert status='error'>
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        {showResetconfirmation && (
          <Alert status='info'>
            <AlertIcon />
            <AlertDescription>An email with instructions to reset your password has been sent to {email}</AlertDescription>
          </Alert>
        )}
      </VStack>
    </LoginLayout>
  );
}
export default Reset;
