import { Table, TableContainer, Tbody, Td, Tr, Thead, Th } from '@chakra-ui/react';
import { SimulationResult } from '../../../../types/SimulationResult';
import { formatNumber } from '../../../../utils/formatters';

interface ResultSummaryProps {
  simulationResult: SimulationResult;
}

export default function ResultSummary(props: ResultSummaryProps) {
  const { tot_kwh } = props.simulationResult.result_summary;

  return (
    <>
      <TableContainer>
        <Table variant='striped' colorScheme={'teal'} mt={4}>
          <Thead>
            <Tr>
              <Th>Simulation summary:</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Total annual energy use:</Td>
              <Td isNumeric>{formatNumber(tot_kwh)} kWh</Td>
            </Tr>
            <Tr>
              <Td>
                Total annual CO<sub>2</sub> emissions:
              </Td>
              <Td isNumeric>{formatNumber(tot_kwh * 0.3)} kg</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
