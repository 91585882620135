const formatNumber = (num: any) => {
  try {
    num = +num;
    if (num === 0) {
      return '-';
    }
    return Intl.NumberFormat('no-NB').format(num.toFixed(2));
  } catch (e) {
    return '-';
  }
};

const formatInteger = (num: any) => {
  try {
    return Intl.NumberFormat('no-NB').format(num.toFixed(0));
  } catch (e) {
    return '-';
  }
};

const formatCapitalFirst = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatMinutes = (time: any) => {
  let total_seconds = time * 60;
  try {
    let minutes = Math.floor(total_seconds / 60);
    let seconds = Math.floor(total_seconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  } catch (e) {
    console.log(e);
    return '';
  }
};
export { formatNumber, formatInteger, formatMinutes, formatCapitalFirst };
