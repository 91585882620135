import { SimpleGrid, AspectRatio, SliderMark, Tooltip, SliderTrack, SliderFilledTrack, SliderThumb, Slider, Text } from '@chakra-ui/react';
import { Box, ButtonGroup, Radio } from '@chakra-ui/react';
import { Formik } from 'formik';
import { InputControl, RadioGroupControl, ResetButton, SelectControl, SubmitButton, TextareaControl } from 'formik-chakra-ui';
import * as Yup from 'yup';
import Map from './components/map/Map';
import { runRoadScenario } from '../../../firestore/simulations';
import { useEffect, useState } from 'react';
import { Route } from '../../../types/RoutePoint';
import OneColumnLayout from '../../layout/OneColLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { getVehicles } from '../../../firestore/vehicles';
import { BreadCrumbInfo } from '../../../types/BreadCrumbInfo';
import { RoadTransportRequest } from '../../../types/RoadTransportRequest';
import { BadRouteError } from './components/errors/BadRouteError';
import { NoRouteError } from './components/errors/NoRouteError';

interface RoadTransportScenarioState {
  projectID: string;
  encodedPath?: any;
}
export default function RoadTransportScenario() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { projectID, encodedPath } = state as RoadTransportScenarioState;

  const breadCrumbs: BreadCrumbInfo[] = [
    { to: '/projects', displayText: 'Projects' },
    { to: '/project', state: { projectID: projectID }, displayText: 'Project overview' },
  ];

  const [scenarioRoute, setScenarioRoute] = useState<Route | null>(null);
  const [mapKey, setMapKey] = useState<number>(1); //hack to force map to re-render on reset
  const [error, setError] = useState<boolean>(false);
  const [noRouteError, setNoRouteError] = useState<boolean>(false);

  const [vehicles, setVehicles] = useState<any[]>();
  const [vehicleFilter, setVehicleFilter] = useState<string>('truck');

  const [sliderValue, setSliderValue] = useState<number>(50);
  const [sliderTooltip, setSliderTooltip] = useState<boolean>(false);
  const [parsedPath, setParsedPath] = useState<any>();

  const onSubmit = async (values: any) => {
    if (!vehicles) return;
    if (!scenarioRoute) {
      setNoRouteError(true);
      return;
    }
    let vehicle = vehicles[values.model];
    let load = (sliderValue * vehicle.max_load_kg) / 100;

    let request: RoadTransportRequest = {
      route: scenarioRoute,
      load_in_kg: Math.round(load),
      notes: values.notes,
      name: values.scenarioName,
      project_id: projectID,
      encoded_path: JSON.stringify(scenarioRoute.waypoints),
      vehicle: JSON.stringify(vehicle),
    };
    let result = await runRoadScenario(request);
    if (!result || result.result === 'error') {
      setError(true);
    } else {
      navigate('/road-transport-result', { state: { ...result } });
    }
  };

  let initialValues = {
    scenarioName: '',
    startPoint: '',
    endPoint: '',
    vehicleType: 'truck',
    notes: '',
    model: 'default',
  };

  const validationSchema = Yup.object({
    scenarioName: Yup.string().required('You need to provide a scenario name'),
    model: Yup.string().notOneOf(['default'], 'You need to select a vehicle'),
  });

  function newRoute(route: Route, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) {
    setScenarioRoute(route);
    setNoRouteError(false);
    setFieldValue('scenarioName', route.scenarioName);
    setFieldValue('startPoint', route.startPoint.name);
    setFieldValue('endPoint', route.endPoint.name);
  }

  function handleReset(_values: any, _formProps: any) {
    setMapKey(Math.round(Math.random() * 100)); //change to update state & force re-render of map .
    setScenarioRoute(null);
    setError(false);
  }

  useEffect(() => {
    getVehicles().then((vehicles: any) => {
      let veh = vehicles.map((vehicle: any, index: any) => ({ ...vehicle, id: index }));
      setVehicles(veh);
    });
    setParsedPath(encodedPath ? JSON.parse(encodedPath) : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function filterVehicles(filter: string, setFieldValue: any) {
    setFieldValue('model', 'default');
    return setVehicleFilter(filter);
  }

  return (
    <OneColumnLayout
      sectionLabel='Create Scenario'
      sectionHeading='New Road Transport Scenario'
      sectionIntro='Create a single vehicle, single route scenario. Click in the map to set waypoints and mark the route. You can drag markers to be as precise as
      possible. You may rename scenario, start and end points. The calculated route will use the the shortest route respecting the order of waypoints.'
      breadCrumbs={breadCrumbs}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width='full'>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} onReset={handleReset} enableReinitialize>
          {({ handleSubmit, values, errors, setFieldValue }) => (
            <>
              <AspectRatio ratio={16 / 9}>
                <Map onRouteChange={(route) => newRoute(route, setFieldValue)} key={mapKey} encodedPath={parsedPath} />
              </AspectRatio>

              <Box borderWidth='1px' rounded='lg' shadow='1px 1px 3px rgba(0,0,0,0.3)' p={6} m='10px auto' as='form' onSubmit={handleSubmit as any}>
                <InputControl name='scenarioName' label='Scenario Name' />

                <InputControl name='startPoint' label='Starting point' mt={4} />
                <InputControl name='endPoint' label='End point' mt={4} />

                <RadioGroupControl name='vehicleType' label='Vehicle Type' mt={4}>
                  <Radio value='truck' onChange={() => filterVehicles('truck', setFieldValue)}>
                    Truck
                  </Radio>
                  <Radio value='bus' onChange={() => filterVehicles('bus', setFieldValue)}>
                    Bus
                  </Radio>
                  <Radio value='car' onChange={() => filterVehicles('car', setFieldValue)}>
                    Car
                  </Radio>
                </RadioGroupControl>

                <SelectControl name='model' label='Model' mt={4} placeholder='Select vehicle'>
                  {vehicles && (
                    <>
                      <option value='default'>Select vehicle</option>
                      {vehicles
                        .filter((vehicle: any) => vehicle.type.toLowerCase() === vehicleFilter)
                        .map((vehicle, index) => (
                          <option key={index} value={vehicle.id}>
                            {vehicle.model}
                          </option>
                        ))}
                    </>
                  )}
                </SelectControl>

                <Text mt={4}>Typical load (% of max)</Text>
                <Slider
                  id='slider'
                  defaultValue={50}
                  min={0}
                  max={100}
                  colorScheme='teal'
                  onChange={(v) => setSliderValue(v)}
                  onMouseEnter={() => setSliderTooltip(true)}
                  onMouseLeave={() => setSliderTooltip(false)}
                  aria-label='load-slider'
                >
                  <SliderMark value={25} mt='1' ml='-2.5' fontSize='sm'>
                    25%
                  </SliderMark>
                  <SliderMark value={50} mt='1' ml='-2.5' fontSize='sm'>
                    50%
                  </SliderMark>
                  <SliderMark value={75} mt='1' ml='-2.5' fontSize='sm'>
                    75%
                  </SliderMark>
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <Tooltip hasArrow bg='teal.500' color='white' placement='top' isOpen={sliderTooltip} label={`${sliderValue}%`}>
                    <SliderThumb />
                  </Tooltip>
                </Slider>

                <TextareaControl name='notes' label='Additional notes' helperText='Optional - if you need to note down something about this scenario.' mt={4} />
                {noRouteError && <NoRouteError />}
                <ButtonGroup mt={4}>
                  <SubmitButton loadingText='Calculating.. this could take some time'>Run Scenario</SubmitButton>
                  <ResetButton>Reset</ResetButton>
                </ButtonGroup>
              </Box>
            </>
          )}
        </Formik>
        {error && <BadRouteError />}
      </SimpleGrid>
    </OneColumnLayout>
  );
}
