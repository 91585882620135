import { useEffect, useState } from 'react';
import { GraphData, GraphPoint } from '../../../../../types/TimeGraphData';
import MinutesGraph from '../../../../layout/graphs/MinutesGraph';

interface CO2AccumulatedGraphProps {
  time_series: any;
}
export default function CO2AccumulatedGraph(props: CO2AccumulatedGraphProps) {
  const { time_series } = props;
  const [graphData, setGraphData] = useState<GraphData>();

  useEffect(() => {
    if (time_series) {
      let data: GraphPoint[] = [];
      let timestamp = 0;
      let accumulated_co2_g = 0;

      try {
        for (var index in time_series.duration_s) {
          timestamp += time_series.duration_s[index];
          accumulated_co2_g += time_series.emissions_co2_g[index];

          data.push({ x: timestamp / 60, y: accumulated_co2_g / 1000 }); // g to kg
        }
      } catch (err) {
        console.log(err);
      }

      setGraphData([
        {
          id: 'Emissions',
          color: 'teal',
          data: data,
        },
      ]);
    }
  }, [time_series]);

  return (
    <>
      {graphData && (
        <MinutesGraph
          legendX='Minutes'
          legendY='Kg CO2'
          data={graphData}
          heading={'Accumulated emissions'}
          description={'Emmissions accumulated by time spent en route'}
        />
      )}
    </>
  );
}
