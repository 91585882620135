import { useEffect, useState } from 'react';
import { GraphData, GraphPoint } from '../../../../../types/TimeGraphData';
import MinutesGraph from '../../../../layout/graphs/MinutesGraph';

interface CO2AccumulatedGraphProps {
  time_series: any;
}
export default function CO2AccumulatedGraph(props: CO2AccumulatedGraphProps) {
  const { time_series } = props;
  const [graphData, setGraphData] = useState<GraphData>();

  useEffect(() => {
    if (time_series) {
      let data: GraphPoint[] = [];
      let timestamp = 0;
      let accumulated_co2_kg = 0;

      time_series.forEach((step: { time_step_s: number; co2_kg: number }) => {
        timestamp += step.time_step_s / 60;
        accumulated_co2_kg += step.co2_kg;
        data.push({ x: timestamp, y: accumulated_co2_kg });
      });

      setGraphData([
        {
          id: 'Emissions',
          color: 'teal',
          data: data,
        },
      ]);
    }
  }, [time_series]);

  return (
    <>
      {graphData && (
        <MinutesGraph
          legendX='Minutes'
          legendY='Kg CO2'
          data={graphData}
          heading={'Accumulated emissions'}
          description={'Emmissions accumulated by time spent'}
        />
      )}
    </>
  );
}
