import { httpsCallable } from 'firebase/functions';
import { functions } from './firebaseConfig';

const sendFeedbackEmail = async (name: string, email: string, message: string) => {
  try {
    const sendFeedbackEmail = httpsCallable(functions, 'sendFeedbackEmail');
    let response = await sendFeedbackEmail({ name: name, email: email, message: message });
    console.log(response);
  } catch (err) {
    console.error(err);
  }
};

export { sendFeedbackEmail };
