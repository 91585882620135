import { Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';

export function BadRouteError() {
  return (
    <Alert status='error'>
      <AlertIcon />
      <AlertDescription>
        We could not run the scenario for this route. Try selecting a shorter route, and make sure both start and end points are located on land and close to a
        road.
      </AlertDescription>
    </Alert>
  );
}
