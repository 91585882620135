import { useEffect, useState } from 'react';
import { GraphPoint } from '../../../../../types/TimeGraphData';
import MinutesGraph from '../../../../layout/graphs/MinutesGraph';

interface TotalPowerGraphProps {
  time_series: any;
}
export default function TotalPowerGraph(props: TotalPowerGraphProps) {
  const { time_series } = props;

  const [graphData, setGraphData] = useState<any>();

  useEffect(() => {
    if (time_series) {
      let data: GraphPoint[] = [];
      let timestamp = 0;

      time_series.forEach((step: { time_step_s: number; total_power_kw: number }) => {
        timestamp += step.time_step_s / 60;
        let power = Math.round(step.total_power_kw * 100) / 100; //javascript math is stupid.
        data.push({ x: timestamp, y: power });
      });

      setGraphData([
        {
          id: 'Power',
          color: 'teal',
          data: data,
        },
      ]);
    }
  }, [time_series]);

  return (
    <>
      <MinutesGraph legendX='Minutes' legendY='kW' data={graphData} heading={'Power use'} description={'Power usage en route.'} />
    </>
  );
}
