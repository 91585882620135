import { Tabs, Tab, TabList, TabPanel, TabPanels, Flex, Box, Heading } from '@chakra-ui/react';
import { BreadCrumbInfo } from '../../types/BreadCrumbInfo';
import OneColumnLayout from '../layout/OneColLayout';
import AddAccountingProject from './components/AddAccountingProject';
import AddRoutePlanningProject from './components/AddRoutePlanningProject';
import ExplainAccountingModules from './components/ExplainAccountingModules';
import ExplainRoutePlanningModules from './components/ExplainRoutePlanningModules';

export default function CreateProject() {
  const breadCrumbs: BreadCrumbInfo[] = [{ to: '/projects', displayText: 'Projects' }];

  return (
    <>
      <OneColumnLayout
        sectionLabel='Create Project'
        sectionHeading='Set up a new project'
        sectionIntro='To start calculating emissions and costs you must first set up a project. If you wish to run quick comparisons for a specific route, select Route Planning. If you are planning to model a more complex energy system you should choose Systems Modeling.'
        breadCrumbs={breadCrumbs}
      >
        <>
          <Tabs variant='enclosed' colorScheme={'teal'}>
            <TabList>
              <Tab>Route Planning</Tab>
              <Tab>Systems Modeling</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Heading size={'md'} mt={6} mb={4}>
                  Compare multipe vehicles across the same route
                </Heading>
                <Flex flexDirection={{ sm: 'column', md: 'row' }}>
                  <Box minW={['sm']} mr={{ sm: 0, md: 10 }}>
                    <AddRoutePlanningProject />
                  </Box>
                  <Box w={'100%'}>
                    <ExplainRoutePlanningModules />
                  </Box>
                </Flex>
              </TabPanel>
              <TabPanel>
                <Heading size={'md'} mt={6} mb={4}>
                  Model systems with several different energy actors
                </Heading>
                <Flex flexDirection={{ sm: 'column', md: 'row' }}>
                  <Box minW={['sm']} mr={{ sm: 0, md: 10 }}>
                    <AddAccountingProject />
                  </Box>
                  <Box w={'100%'}>
                    <ExplainAccountingModules />
                  </Box>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      </OneColumnLayout>
    </>
  );
}
