import { useEffect, useState } from 'react';
import { GraphData } from '../../../../../types/TimeGraphData';
import MinutesGraph from '../../../../layout/graphs/MinutesGraph';

interface ElevationGraphProps {
  time_series: any;
}

export default function ElevationGraph(props: ElevationGraphProps) {
  const { time_series } = props;
  const [graphData, setGraphData] = useState<GraphData>();

  useEffect(() => {
    if (time_series) {
      let data = [];
      let timestamp = 0;
      let graphData: any[];

      try {
        let elevation = time_series.driven_elevation_m ? time_series.driven_elevation_m : time_series.elevation_m;
        for (var index in time_series.duration_s) {
          timestamp += time_series.duration_s[index];
          let el = elevation[index];
          data.push({ x: Math.round(timestamp) / 60, y: el });
        }
        //remove elevations that are identical in time
        let p = data.filter(function (item, position, array) {
          return !position || item.x !== array[position - 1].x;
        });

        //round minutes to two decimals
        graphData = p.map((item) => {
          return { x: +item.x.toFixed(2), y: item.y };
        });
      } catch (err) {
        console.log(err);
        graphData = [];
      }

      setGraphData([
        {
          id: 'Elevation',
          color: 'teal',
          data: graphData,
        },
      ]);
    }
  }, [time_series]);

  return (
    <>
      {graphData && (
        <MinutesGraph
          legendX={'Minutes'}
          legendY={'Metres above sea level'}
          data={graphData}
          heading={'Route elevation'}
          description={'Driven elevation of route, accounting for bridges and tunnels'}
        />
      )}
    </>
  );
}
