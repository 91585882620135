import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SimulationResult } from '../../../types/SimulationResult';
import { formatNumber } from '../../../utils/formatters';

interface ScenarioInfoProps {
  scenarios: SimulationResult[];
  activeScenarios: any[];
}

export default function ScenarioInfoBuilding(props: ScenarioInfoProps) {
  const [scenarios, setScenarios] = useState<SimulationResult[]>([]);
  const [activeScenarios, setActiveScenarios] = useState<any>([]);

  useEffect(() => {
    setActiveScenarios(props.activeScenarios);
    setScenarios(props.scenarios);
  }, [props]);

  return (
    <Table variant={'striped'} colorScheme={'teal'} mt={4}>
      <Thead>
        <Tr>
          <Th>Scenario Name</Th>
          <Th isNumeric>Annual Energy use (kWh)</Th>
          <Th isNumeric>
            Annual C0<sub>2</sub> emissions (tonnes)
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {activeScenarios.map((as: any) => {
          if (!as.active) return null;
          let sim = scenarios[scenarios.findIndex((sim) => sim.id === as.id)];
          if (!sim) return null;
          return (
            <Tr key={sim.id}>
              <Td fontWeight={'bold'}>{sim.name}</Td>
              <Td isNumeric>{formatNumber(sim.result_summary.tot_kwh)}</Td>
              <Td isNumeric>{formatNumber(sim.result_summary.tot_kwh * 0.3)}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
