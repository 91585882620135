import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { registerWithEmailAndPassword } from '../../firestore/authentication';
import { auth } from '../../firestore/firebaseConfig';

import { useNavigate } from 'react-router-dom';
import { Input, VStack, Button, Link, Text, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import { Logo } from '../layout/Logo';
import LoginLayout from '../layout/LoginLayout';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [user, loading] = useAuthState(auth);
  const [hasError, setError] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const register = () => {
    if (!validateInput()) return;
    registerWithEmailAndPassword(name, email, password)
      .then()
      .catch((error) => {
        setError(true);
        switch (error.code) {
          case 'auth/invalid-email':
            setErrorMessage('The email you are trying to use is not a valid email address.');
            break;
          case 'auth/weak-password':
            setErrorMessage('Please choose a stronger password.');
            break;
          case 'auth/email-already-in-use':
            setErrorMessage('This email already has an account');
            break;
          default:
            setErrorMessage('Somthing went wrong during registration. If the problem persists, please contact us.');
            break;
        }
      });
  };

  function validateInput() {
    if (!name) {
      setError(true);
      setErrorMessage('Please fill in your name to register');
      return false;
    }
    if (!email) {
      setError(true);
      setErrorMessage('Please fill in your email to register.');
      return false;
    }
    if (!password || password.length < 6) {
      setError(true);
      setErrorMessage('Please fill in a password. The password must be 6 characters or more.');
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (loading) return;
    if (user) navigate('/dashboard');
  }, [user, loading, navigate]);

  return (
    <LoginLayout>
      <VStack maxW='md'>
        <Logo h='40vmin' pointerEvents='none' />
        <Input value={name} onChange={(e) => setName(e.target.value)} placeholder='Full Name' />
        <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='E-mail Address' />
        <Input value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' type='password' />
        <Button onClick={register}>Register</Button>
        <Text>
          Already have an account?{' '}
          <Link color='teal.500' href='/login'>
            Log in
          </Link>
        </Text>
        {hasError && (
          <Alert status='error'>
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
      </VStack>
    </LoginLayout>
  );
}
export default Register;
