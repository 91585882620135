import { SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Button, Box, Spinner } from '@chakra-ui/react';
import OneColumnLayout from '../../layout/OneColLayout';
import { useLocation } from 'react-router-dom';
import { BreadCrumbInfo } from '../../../types/BreadCrumbInfo';
import { SimulationResult } from '../../../types/SimulationResult';
import ResultSummary from './components/ResultSummary';
import { useEffect, useState } from 'react';
import { getTimeSeries, saveSimulation } from '../../../firestore/simulations';
import ProductionGraph from './components/graphs/ProductionGraph';
import AccumulatedProductionGraph from './components/graphs/AccumulatedProduction';

export default function SolarResult() {
  const { state } = useLocation();
  const simulation_result = state as SimulationResult;
  const breadCrumbs: BreadCrumbInfo[] = [
    { to: '/projects', displayText: 'Projects' },
    { to: '/project', state: { projectID: simulation_result.project_id }, displayText: 'Project overview' },
    { to: '/solar-scenario', state: { projectID: simulation_result.project_id }, displayText: 'Solar scenario' },
  ];

  const [saving, setSaving] = useState<boolean>(false);
  const [saveButtonText, setSaveButtonText] = useState<string>('Save Results');
  const [timeSeries, setTimeSeries] = useState<any>();

  //const [TCOvalues, setTCO] = useState<any>();

  useEffect(() => {
    if (simulation_result.time_series) {
      setTimeSeries(simulation_result.time_series);
      return;
    } else if (simulation_result.id) {
      getTimeSeries(simulation_result.id)?.then((time_series) => {
        setTimeSeries(time_series[0].time_steps);
      });
    }
  }, [simulation_result]);

  function save() {
    setSaving(true);
    setSaveButtonText('Saving...');
    let saveRequest = {
      ...simulation_result,
      calculated_values: {
        energy_use_annual: -simulation_result.result_summary.wh / 1000,
        co2_emissions_annual: simulation_result.result_summary.co2_kg,
        total_cost_of_ownership: simulation_result.result_summary.tco_kr_kwh,
      },
    };
    saveSimulation(saveRequest).then((res: any) => {
      setSaveButtonText(res.result === 'success' ? 'Results saved' : 'Could not save result');
    });
  }

  return (
    <OneColumnLayout
      sectionLabel='Scenario result'
      sectionHeading={`Scenario run results for ${simulation_result.name}`}
      sectionIntro=''
      breadCrumbs={breadCrumbs}
    >
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} width='full' p={4}>
        <Button onClick={save} disabled={saving} colorScheme='teal'>
          {saveButtonText}
        </Button>
      </SimpleGrid>
      <Tabs variant='enclosed' colorScheme={'teal'}>
        <TabList>
          <Tab>Summary</Tab>
          <Tab>Graphs</Tab>
        </TabList>
        <TabPanels maxW={800}>
          <TabPanel>
            <ResultSummary simulationResult={simulation_result} />
          </TabPanel>
          <TabPanel>
            {timeSeries ? (
              <>
                <ProductionGraph time_series={timeSeries} />
                <AccumulatedProductionGraph time_series={timeSeries} />
              </>
            ) : (
              <Box width={'full'} display={'flex'} mt={40}>
                <Spinner p={6} margin={'auto'} size='xl' />
              </Box>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </OneColumnLayout>
  );
}
