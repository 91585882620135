import { Box, Button, Flex, Grid } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteProject } from '../../../firestore/projects';
import { Alert } from '../../layout/AlertDialog';
import { ModuleIcons } from '../../layout/ModuleIcons';
import { ProjectType } from '../../../types/ProjectType';

interface ProjectRowProps {
  project: { name: string; modules: string[]; user_id: string; id: string; type: string };
}

export default function ProjectRow(props: ProjectRowProps) {
  const { name, modules, id, type } = props.project;
  const navigate = useNavigate();
  const [renderMe, setRenderMe] = useState<boolean>(true);

  function goToProjectOverview() {
    if (type === ProjectType.RoutePlanning) {
      navigate('/route-planning-overview', { state: { projectName: name, projectID: id } });
    } else {
      navigate('/project', { state: { projectName: name, projectID: id, modules: modules } });
    }
  }

  function onDeleteProject(projectID: string) {
    deleteProject(projectID).then(() => {
      setRenderMe(false);
    });
  }

  if (!renderMe) {
    return <></>;
  }
  return (
    <Grid mb={6} templateColumns='4fr 1fr 2fr 30px' gap={6}>
      <Box>{name}</Box>
      <Flex width={240} justifyContent={'end'}>
        <ModuleIcons modules={modules} />
      </Flex>
      <Box>
        <Button onClick={() => goToProjectOverview()}>Go to project page</Button>
      </Box>
      <Box>
        <Alert onConfirm={() => onDeleteProject(id)} />
      </Box>
    </Grid>
  );
}
