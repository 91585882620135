import { Stack, Flex, Text, useColorModeValue, Icon } from '@chakra-ui/react';
import { MdDirectionsBoat, MdDirectionsBus, MdQuestionAnswer } from 'react-icons/md';
import { ImOffice, ImPower, ImPowerCord } from 'react-icons/im';
import { FaSolarPanel } from 'react-icons/fa';
import { ScenarioType } from '../../types/ScenarioType';

interface FeatureProps {
  text: string;
  type: string;
}
export const ModuleName = ({ text, type }: FeatureProps) => {
  let color = '';
  let icon;
  switch (type) {
    case ScenarioType.SeaTransport:
      color = 'blue';
      icon = MdDirectionsBoat;
      break;
    case ScenarioType.RoadTransport:
      color = 'green';
      icon = MdDirectionsBus;
      break;
    case ScenarioType.Building:
      color = 'red';
      icon = ImOffice;
      break;
    case ScenarioType.Solar:
      color = 'yellow';
      icon = FaSolarPanel;
      break;
    case ScenarioType.ElectricityConsumer:
      color = 'purple';
      icon = ImPowerCord;
      break;
    case ScenarioType.ElectricitySupplier:
      color = 'orange';
      icon = ImPower;
      break;
    default:
      color = 'teal';
      icon = MdQuestionAnswer;
  }

  return (
    <Stack direction={'row'} align={'center'}>
      <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={useColorModeValue(`${color}.100`, `${color}.100`)}>
        <Icon as={icon} color={`${color}.500`} />
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};
