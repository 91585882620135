import { Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';

export function NoRouteError() {
  return (
    <Alert status='error' mt={2}>
      <AlertIcon />
      <AlertDescription>You must select a route for this scenario. Click on the map to set both start and endpoints.</AlertDescription>
    </Alert>
  );
}
