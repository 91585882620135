import { Box } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';

const googleMapsApiKey = 'AIzaSyCmpWpPIUgLejuVqKgXn-jub7gOQk-XBW8';
const midNorway = { lat: 60.843574, lng: 8.2469489 };
const zoom = 5;

interface MapProps {
  onSetPoint: (point: any) => void;
}

export default function Map(props: MapProps) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey,
  });
  const { onSetPoint } = props;
  const [point, setPoint] = useState<google.maps.LatLng>();

  useEffect(() => {
    if (isLoaded) {
      setPoint(new google.maps.LatLng(midNorway));
    }
  }, [isLoaded]);

  function onClickMap(event: google.maps.MapMouseEvent) {
    if (event.latLng) setPoint(event.latLng);
    onSetPoint({ name: 'name', latLng: event.latLng });
  }

  return (
    <>
      {isLoaded ? (
        <GoogleMap center={midNorway} zoom={zoom} onClick={onClickMap}>
          {point && <Marker position={point}></Marker>}
        </GoogleMap>
      ) : (
        <Box>
          <Spinner size='xl' />
        </Box>
      )}
    </>
  );
}
