import { Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import React, { ReactNode, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firestore/firebaseConfig';
import { BreadCrumbInfo } from '../../types/BreadCrumbInfo';
import Breadcrumbs from './BreadCrumb';
import { Footer } from './Footer';
import Header from './Header';
import SectionLabel from './SectionLabel';

interface TwoColProps {
  left: ReactNode;
  right: ReactNode;
  sectionLabel: string;
  sectionHeading: string;
  sectionIntro: string;
  breadCrumbs?: BreadCrumbInfo[];
}
function TwoColumnLayout(props: TwoColProps) {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
  }, [user, loading, navigate]);

  return (
    <Grid minH='100vh' templateRows='100px 60px 60px min-content 8fr 50px' templateColumns={'1fr 1fr'}>
      <GridItem colSpan={2}>
        <Header />
      </GridItem>
      <GridItem colSpan={2} m={6} mt={2} display={'flex'}>
        {props.breadCrumbs && <Breadcrumbs links={props.breadCrumbs} />}
        <SectionLabel>{props.sectionLabel}</SectionLabel>
      </GridItem>
      <GridItem colSpan={2} m={6}>
        <Heading alignSelf={'flex-start'}>{props.sectionHeading}</Heading>
      </GridItem>
      <GridItem colSpan={2} m={6}>
        <Text color={'gray.500'} fontSize={'lg'} alignSelf={'flex-start'}>
          {props.sectionIntro}
        </Text>
      </GridItem>
      <GridItem p={6} colSpan={[2, 2, 1]}>
        {props.left}
      </GridItem>
      <GridItem p={6} colSpan={[2, 2, 1]}>
        {props.right}
      </GridItem>
      <GridItem colSpan={2}>
        <Footer />
      </GridItem>
    </Grid>
  );
}

export default TwoColumnLayout;
