import { SimpleGrid, Heading, Stack, Button, GridItem, Box, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProject } from '../../firestore/projects';
import { getSimulationsSummarised } from '../../firestore/simulations';
import { BreadCrumbInfo } from '../../types/BreadCrumbInfo';
import { SimulationResult } from '../../types/SimulationResult';
import OneColumnLayout from '../layout/OneColLayout';
import Components from './components/Components';
import EmissionsSummary from './components/EmissionsSummary';
import EnergyUseSummary from './components/EnergyUseSummary';
import Simulations from './components/Simulations';

interface ProjectState {
  projectName: string;
  projectID: string;
  modules: string[];
}

export default function AccountingOverview() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const breadCrumbs: BreadCrumbInfo[] = [{ to: '/projects', displayText: 'Projects' }];

  const { projectName, modules, projectID } = state as ProjectState;
  const [projectModules, setModules] = useState<any>(modules);
  const [name, setName] = useState<any>(projectName);
  const [simulations, setSimulations] = useState<SimulationResult[]>();

  useEffect(() => {
    if (modules === undefined || projectName || undefined) {
      getProject(projectID).then((project) => {
        if (project) {
          setModules(project.modules);
          setName(project.name);
        }
      });

      loadSimulations();
    }
    //load simulations is not a real dependency, and it would cause endless re-render ot include it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules, projectID, projectName]);

  function goToComparison() {
    navigate('/compare', { state: { projectID: projectID } });
  }

  function loadSimulations() {
    getSimulationsSummarised(projectID)?.then((simulations) => {
      setSimulations(simulations as SimulationResult[]);
    });
  }

  return (
    <OneColumnLayout
      sectionLabel='Project Overview'
      sectionHeading={name}
      // sectionIntro='Key information about your project will be shown here. Start by adding data through the options below.'
      sectionIntro='Energy consumption and emissions overview'
      breadCrumbs={breadCrumbs}
    >
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10} width='full' p={4}>
        <GridItem h={200} border='1px solid #f0f0f0'>
          {simulations && <EmissionsSummary simulations={simulations} />}
        </GridItem>
        <GridItem border='1px solid #f0f0f0'>{simulations && <EnergyUseSummary simulations={simulations} />}</GridItem>
        {/* <GridItem border='1px solid #f0f0f0'>Total_asset_cost, total_energy_cost</GridItem> */}
        <GridItem border='1px solid #f0f0f0' p={6} display={'flex'} alignItems={'center'}>
          <Box>
            <Button mb={4} disabled w='100%' maxW={300}>
              Edit calculation components
            </Button>
            <Button onClick={() => goToComparison()} w='100%' maxW={300}>
              Compare saved scenarios
            </Button>
          </Box>
        </GridItem>
      </SimpleGrid>
      <Heading size='lg' mt={8} mb={4}>
        Add a project component
      </Heading>
      <Components projectID={projectID} modules={projectModules} />
      <Button m={3} disabled>
        Add a dataset - coming soon
      </Button>
      <Button m={3} disabled>
        Request a dataset - coming soon
      </Button>
      <Button m={3} disabled>
        Add a report - coming soon
      </Button>

      <Stack mt={20} p={4}>
        <Heading size='md'>Saved scenarios</Heading>
        {simulations ? (
          <Simulations simulations={simulations} projectID={projectID} onDelete={loadSimulations} />
        ) : (
          <Box width={'full'} display={'flex'}>
            <Spinner p={6} margin={'auto'} size='xl' />
          </Box>
        )}
      </Stack>
    </OneColumnLayout>
  );
}
