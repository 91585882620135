/* global google */
import { useState, useEffect, useRef } from 'react';
import { DirectionsRenderer, Marker } from '@react-google-maps/api';
import { Route } from '../../../../../types/RoutePoint';

interface MapDirectionsRendererProps {
  onRouteChange: (details: Route) => void;
  onError: (error: string) => void;
  places: google.maps.LatLng[];
  travelMode: google.maps.TravelMode;
  displayOnly?: boolean;
}

export default function MapDirectionsRenderer(props: MapDirectionsRendererProps) {
  const { places, travelMode, onRouteChange, onError, displayOnly } = props;

  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);
  const [error, setError] = useState<google.maps.DirectionsResult | null>(null);

  const directionsRef = useRef<DirectionsRenderer | null>(null);

  useEffect(() => {
    if (places.length < 2) return; //need at least two places to make a route

    const waypoints = places.map((p: google.maps.LatLng) => ({
      location: p,
      stopover: true,
    }));

    const origin = waypoints.shift()?.location;
    const destination = waypoints.pop()?.location;
    if (!origin || !destination) return;
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: waypoints,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
          if (result) onRouteChange(buildRoute(result));
        } else if (status === google.maps.DirectionsStatus.ZERO_RESULTS) {
          setError(result);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places, travelMode]);

  function onDirectionsChanged() {
    let ref: any = directionsRef.current;
    if (ref) {
      let directions: google.maps.DirectionsResult = ref.state.directionsRenderer.getDirections();
      onRouteChange(buildRoute(directions));
    }
  }

  function buildRoute(directions: google.maps.DirectionsResult) {
    let legs = directions.routes[0].legs;
    let scenarioName = directions.routes[0].summary;
    const last = legs.length - 1;

    let startPoint = {
      name: legs[0].start_address,
      latLng: legs[0].start_location,
    };

    let endPoint = {
      name: legs[last].end_address,
      latLng: legs[last].end_location,
    };

    let waypoints = legs.map((leg) => {
      return [leg.start_location.lat(), leg.start_location.lng()];
    });
    waypoints.push([endPoint.latLng.lat(), endPoint.latLng.lng()]);

    return { scenarioName, waypoints, startPoint, endPoint };
  }

  function getOptions() {
    if (displayOnly) {
      return { draggable: false };
    }
    return { draggable: true };
  }

  if (error) {
    setError(null);
    onError('error drawing route');
  }

  if (places.length >= 2) {
    return directions && <DirectionsRenderer directions={directions} options={getOptions()} ref={directionsRef} onDirectionsChanged={onDirectionsChanged} />;
  }

  if (places.length === 1) return <Marker position={places[0]} />;

  return <></>;
}
