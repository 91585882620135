import { Button, SimpleGrid, Tabs, TabList, Tab, TabPanels, TabPanel, Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getTimeSeries, saveSimulation } from '../../../firestore/simulations';
import { SimulationResult } from '../../../types/SimulationResult';
import EffectGraph from './components/graphs/EffectGraph';
import ElevationGraph from './components/graphs/ElevationGraph';
import ResultSummary from './components/ResultSummary';
import SpeedGraph from './components/graphs/SpeedGraph';
import OneColumnLayout from '../../layout/OneColLayout';
import TCO from './components/TCO';
import { BreadCrumbInfo } from '../../../types/BreadCrumbInfo';
import { ScenarioType } from '../../../types/ScenarioType';
import CO2AccumulatedGraph from './components/graphs/CO2AccumulatedGraph';

export default function RoadScenarioResult() {
  const { state } = useLocation();
  let simulation_result = state as SimulationResult;

  const breadCrumbs: BreadCrumbInfo[] = [
    { to: '/projects', displayText: 'Projects' },
    { to: '/project', state: { projectID: simulation_result.project_id }, displayText: 'Project overview' },
    { to: '/road-transport-scenario', state: { projectID: simulation_result.project_id }, displayText: 'Road Scenario' },
  ];

  const [saving, setSaving] = useState<boolean>(false);
  const [saveButtonText, setSaveButtonText] = useState<string>('Save Results');

  const [TCOvalues, setTCO] = useState<any>();

  const [timeSeries, setTimeSeries] = useState<any>();

  const encodedPath = simulation_result.inputs.encoded_path;
  const vehicle = JSON.parse(simulation_result.inputs.vehicle);
  const color = vehicle && vehicle.drivetrain.toLowerCase() === 'electric' ? 'teal' : 'orange';

  useEffect(() => {
    const isSaved = !!simulation_result.id;
    if (isSaved) {
      setSaveButtonText('Save updated result');
      setSaving(false);
    }

    if (!timeSeries) {
      if (simulation_result.time_series) {
        setTimeSeries(simulation_result.time_series);
        return;
      } else if (simulation_result.id && isSaved) {
        getTimeSeries(simulation_result.id)?.then((time_series) => {
          setTimeSeries(time_series);
        });
      }
    }
  }, [simulation_result, TCOvalues, timeSeries]);

  function save() {
    setSaving(true);
    setSaveButtonText('Saving...');
    let saveRequest = {
      ...simulation_result,
      calculated_values: {
        energy_use_annual: TCOvalues.total_consumed_energy_kwh,
        co2_emissions_annual: TCOvalues.total_co2_emissions_ton,
        total_cost_of_ownership: TCOvalues,
      },
      inputs: {
        ...simulation_result.inputs,
        route: JSON.stringify(simulation_result.inputs.route),
      },
    };
    saveSimulation({ ...saveRequest, type: ScenarioType.RoadTransport })
      .then((res: any) => {
        setSaveButtonText(res.result === 'success' ? 'Results saved' : 'Could not save result');
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  return (
    <OneColumnLayout
      sectionLabel='Scenario Result'
      sectionHeading={`Scenario run results for ${simulation_result.name}`}
      sectionIntro={simulation_result.notes}
      breadCrumbs={breadCrumbs}
    >
      <>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} width='full' p={4}>
          <Button onClick={save} disabled={saving} colorScheme='teal'>
            {saveButtonText}
          </Button>
          <Button as={Link} to='/road-transport-scenario' state={{ projectID: simulation_result.project_id }}>
            New simulation
          </Button>
          <Button as={Link} to='/road-transport-scenario' state={{ projectID: simulation_result.project_id, encodedPath: encodedPath }}>
            New simulation with same route
          </Button>
        </SimpleGrid>
        <Tabs variant='enclosed' colorScheme={color}>
          <TabList>
            <Tab>Trip summary</Tab>
            <Tab>Trip graphs</Tab>
            <Tab>Total cost of ownership</Tab>
          </TabList>
          <TabPanels maxW={800}>
            <TabPanel>
              <ResultSummary simulationResult={simulation_result} />
            </TabPanel>
            <TabPanel>
              <Box>
                <ElevationGraph time_series={timeSeries} />
                <EffectGraph time_series={timeSeries} />
                <SpeedGraph time_series={timeSeries} />
                <CO2AccumulatedGraph time_series={timeSeries} />
              </Box>
            </TabPanel>
            <TabPanel>
              <TCO simulationResult={simulation_result} onUpdate={setTCO} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    </OneColumnLayout>
  );
}
