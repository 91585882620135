import { SimulationResult } from '../../../../types/SimulationResult';
import { formatNumber, formatCapitalFirst } from '../../../../utils/formatters';
import { TCOcalculations } from './TCOcalculations';

export function generateTabledata(roadScenarios: SimulationResult[]) {
  let data = [];
  for (const sim of roadScenarios) {
    if (sim.active) {
      let tco = new TCOcalculations(sim);
      let vehicle = JSON.parse(sim.inputs.vehicle);

      data.push({
        scenario_name: sim.name,
        route_length: formatNumber(sim.result_summary.route_length_m / 1000),
        duration: formatNumber(sim.result_summary.route_duration_s / 60),
        emissions: formatNumber(sim.result_summary.emissions_co2_g / 1000),
        fuel_consumption: formatNumber(sim.result_summary.fuel_consumption_unit / 1000),
        energy_consumption: formatNumber((sim.result_summary.energy_consumption_kWh_km * sim.result_summary.route_length_m) / 1000),
        charge_stops: formatNumber(sim.result_summary.estimated_charges),
        vehicle_model: sim.result_summary.vehicle_model,
        drivetrain: formatCapitalFirst(vehicle.drivetrain),
        load: formatNumber(sim.inputs.load_in_kg),
        energy_efficiency: formatNumber(sim.result_summary.energy_consumption_kWh_km),
        fuel_consumption_10km: formatNumber(sim.result_summary.fuel_consumption_unit_10km / 1000),
        trips_per_month: formatNumber(tco.trips_per_month),
        km_per_year: formatNumber(tco.km_year),
        total_maintenance: formatNumber(tco.total_maintenance),
        write_off_yr: formatNumber(tco.write_off_avg_yr),
        insurance: formatNumber(tco.insurance_price_yr),
        total_charge_cost: formatNumber(tco.total_charge_cost),
        total_fuel_cost: formatNumber(tco.total_fuel_cost),
        total_annual_cost: formatNumber(tco.total_annual_cost),
        cost_per_km: formatNumber(tco.total_annual_cost / tco.km_year),
        annual_emissions: formatNumber(tco.total_co2_emissions / 1000),
        emissions_per_km: formatNumber(tco.total_co2_emissions / tco.km_year),
      });
    }
  }

  return {
    data: data,
    headings: [
      { label: 'Scenario Name', key: 'scenario_name' },
      { label: 'Route length (km)', key: 'route_length' },
      { label: 'Duration (min)', key: 'duration' },
      { label: 'CO₂ emissions (kg)', key: 'emissions' },
      { label: 'Fuel consumption (l)', key: 'fuel_consumption' },
      { label: 'Energy Consumption (kWh)', key: 'energy_consumption' },
      { label: 'Charging stops', key: 'charge_stops' },
      { label: 'Vehicle model', key: 'vehicle_model' },
      { label: 'Drive', key: 'drivetrain' },
      { label: 'Load (kg)', key: 'load' },
      { label: 'Energy efficiency (kWh / km)', key: 'energy_efficiency' },
      { label: 'Fuel efficiency (l/10km)', key: 'fuel_consumption_10km' },
      { label: 'Trips per month', key: 'trips_per_month' },
      { label: 'Km per year', key: 'km_per_year' },
      { label: 'Maintenance cost (year)', key: 'total_maintenance' },
      { label: 'Annualised purchase cost', key: 'write_off_yr' },
      { label: 'Insurance', key: 'insurance' },
      { label: 'Total Charge cost', key: 'total_charge_cost' },
      { label: 'Total Fuel cost kr', key: 'total_fuel_cost' },
      { label: 'Annual TCO (kr)', key: 'total_annual_cost' },
      { label: 'Cost per km (kr)', key: 'cost_per_km' },
      { label: 'Annual CO₂ emissions (tons)', key: 'annual_emissions' },
      { label: 'CO₂ emissions per km (kg)', key: 'emissions_per_km' },
    ],
  };
}
