import { httpsCallable } from 'firebase/functions';
import { auth, functions } from './firebaseConfig';

import { SimulationResult } from '../types/SimulationResult';
import { SeaScenarioRequest } from '../types/SeaScenarioRequest';
import { BuildingScenarioRequest } from '../types/BuildingScenarioRequest';
import { MultipleRoadTransportRequest, RoadTransportRequest } from '../types/RoadTransportRequest';
import { SolarRequest } from '../types/SolarRequest';

const getSimulationsSummarised = async (projectID: string) => {
  try {
    const getSimulationsSummarised = httpsCallable(functions, 'getSimulationsSummarised');
    let response = await getSimulationsSummarised({ project_id: projectID });
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const saveSimulation = async (simulation: SimulationResult) => {
  try {
    const saveSimulation = httpsCallable(functions, 'saveSimulation');
    let response = await saveSimulation(simulation);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const deleteSimulation = async (simulation_id: string) => {
  try {
    const deleteSimulation = httpsCallable(functions, 'deleteSimulation');
    let response = await deleteSimulation({ simulation_id: simulation_id });
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const getSeaScenario = async (request: SeaScenarioRequest) => {
  try {
    const runSeaScenario = httpsCallable(functions, 'runSeaScenario');
    let response = await runSeaScenario({
      ...request,
      uid: auth.currentUser?.uid,
    });
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const runRoadScenario = async (request: RoadTransportRequest) => {
  try {
    const runRoadScenario = httpsCallable(functions, 'runRoadScenario', { timeout: 300000 }); //300s timeout, same as server
    let response = await runRoadScenario({ ...request, route: JSON.stringify(request.route), uid: auth.currentUser?.uid });
    return response.data as any;
  } catch (err) {
    console.error(err);
  }
};

const runMultipleRoadScenarios = async (request: MultipleRoadTransportRequest) => {
  try {
    const runMultipleRoadScenario = httpsCallable(functions, 'runMultipleRoadScenario', { timeout: 540000 }); //540s timeout, same as server
    let response = await runMultipleRoadScenario({ ...request, route: JSON.stringify(request.route), uid: auth.currentUser?.uid });
    return response.data as any;
  } catch (err) {
    console.error(err);
  }
};

const runBuildingScenario = async (request: BuildingScenarioRequest) => {
  try {
    const runBuildingScenario = httpsCallable(functions, 'runBuildingScenario');
    let response = await runBuildingScenario({ ...request });
    return response.data as any;
  } catch (err) {
    console.error(err);
  }
};

const getTimeSeries = async (simulation_id: string) => {
  try {
    const getTimeSeries = httpsCallable(functions, 'getTimeSeries');
    let response = await getTimeSeries({ simulation_id });
    return response.data as any;
  } catch (err) {
    console.error(err);
  }
};

const runSolarScenario = async (request: SolarRequest) => {
  try {
    const runSolarScenario = httpsCallable(functions, 'runSolarScenario', { timeout: 300000 });
    let response = await runSolarScenario({ ...request, uid: auth.currentUser?.uid });
    return response.data as any;
  } catch (err) {
    console.error(err);
  }
};

export {
  getSimulationsSummarised,
  getSeaScenario,
  runRoadScenario,
  runMultipleRoadScenarios,
  runBuildingScenario,
  saveSimulation,
  deleteSimulation,
  getTimeSeries,
  runSolarScenario,
};
