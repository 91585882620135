import { Box, Button, Grid } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { deleteSimulation } from '../../../firestore/simulations';
import { useNavigate } from 'react-router-dom';
import { ModuleIcons } from '../../layout/ModuleIcons';
import { Alert } from '../../layout/AlertDialog';
import { SimulationResult } from '../../../types/SimulationResult';
import { ScenarioType } from '../../../types/ScenarioType';
import { ProjectType } from '../../../types/ProjectType';

interface SimulationsProps {
  projectID: string;
  simulations: SimulationResult[];
  onDelete: any;
  projectType?: ProjectType;
  projectName?: string;
}

export default function Simulations(props: SimulationsProps) {
  const { projectID, onDelete, projectType, projectName } = props;
  const [sims, setSimulations] = useState<SimulationResult[]>();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.simulations) {
      setSimulations(props.simulations);
    }
  }, [props.simulations]);

  function goToResultPage(simulation: SimulationResult) {
    let state = {
      ...simulation,
    };

    if (simulation.type === ScenarioType.SeaTransport) {
      navigate('/sea-transport-result', { state });
    } else if (simulation.type === ScenarioType.RoadTransport) {
      navigate('/road-transport-result', { state });
    } else if (simulation.type === ScenarioType.Building) {
      navigate('/building-result', { state });
    } else if (simulation.type === ScenarioType.ElectricityConsumer) {
      navigate('/electricity-consumer-result', { state });
    } else if (simulation.type === ScenarioType.ElectricitySupplier) {
      navigate('/electricity-supplier-result', { state });
    } else if (simulation.type === ScenarioType.Solar) {
      navigate('/solar-result', { state });
    }
  }

  function onDeleteSimulation(simulationID: string) {
    deleteSimulation(simulationID).then(() => {
      onDelete();
    });
  }

  function goToComparison() {
    const navigateTo = projectType === ProjectType.RoutePlanning ? '/compare_routes' : '/compare';
    navigate(navigateTo, { state: { projectID: projectID, projectName: projectName } });
  }

  if (sims?.length === 0)
    return (
      <Box mt={6} color={'gray'}>
        No saved scenarios
      </Box>
    );
  return (
    <>
      {sims?.map((simulation: SimulationResult, index: number) => (
        <Grid mb={5} templateColumns='40px 1fr 1fr 1fr 30px' gap={6} key={simulation.id}>
          <ModuleIcons modules={[simulation.type]} />
          <Box>{simulation.name}</Box>
          <Box>{simulation.notes}</Box>
          <Box>
            {simulation.type !== ScenarioType.ElectricityConsumer && simulation.type !== ScenarioType.ElectricitySupplier ? (
              <>
                {projectType !== ProjectType.RoutePlanning && (
                  <Button colorScheme='teal' onClick={() => goToResultPage(simulation)}>
                    View
                  </Button>
                )}
                <Button ml={2} colorScheme='teal' onClick={() => goToComparison()}>
                  Compare
                </Button>
              </>
            ) : null}
          </Box>
          <Box>
            <Alert onConfirm={() => onDeleteSimulation(simulation.id || '')} />
          </Box>
        </Grid>
      ))}
    </>
  );
}
