import { Button } from '@chakra-ui/react';
import { CSVLink } from 'react-csv';

export function DownloadLink(props: { data: any; headings: any; title?: string }) {
  const { data, headings, title } = props;

  return (
    <CSVLink data={data || [{}]} headers={headings || []} filename={`seidr_scenarios.csv`} separator=';'>
      <Button colorScheme={'teal'} mt={'4'}>
        {title || 'Download selected'}
      </Button>
    </CSVLink>
  );
}
