import { SimpleGrid, AspectRatio, Checkbox } from '@chakra-ui/react';
import { Box, ButtonGroup, Radio } from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { InputControl, RadioGroupControl, ResetButton, SubmitButton, TextareaControl, CheckboxContainer, SwitchControl } from 'formik-chakra-ui';
import * as Yup from 'yup';
import Map from './components/map/Map';
import { runMultipleRoadScenarios } from '../../../firestore/simulations';
import { useEffect, useState } from 'react';
import { Route } from '../../../types/RoutePoint';
import OneColumnLayout from '../../layout/OneColLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { getVehicles } from '../../../firestore/vehicles';
import { BreadCrumbInfo } from '../../../types/BreadCrumbInfo';
import { MultipleRoadTransportRequest } from '../../../types/RoadTransportRequest';
import { ValueChangeListener } from '../../../utils/ValueChangeListener';
import { BadRouteError } from './components/errors/BadRouteError';
import { NoRouteError } from './components/errors/NoRouteError';

interface RoadTransportScenarioState {
  projectID: string;
  encodedPath?: any;
}
export default function RoadComparisonScenario() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { projectID, encodedPath } = state as RoadTransportScenarioState;

  const breadCrumbs: BreadCrumbInfo[] = [
    { to: '/projects', displayText: 'Projects' },
    { to: '/project', state: { projectID: projectID }, displayText: 'Project overview' },
  ];

  const [scenarioRoute, setScenarioRoute] = useState<Route | null>(null);
  const [mapKey, setMapKey] = useState<number>(1); //hack to force map to re-render on reset
  const [error, setError] = useState<boolean>(false);
  const [noRouteError, setNoRouteError] = useState<boolean>(false);

  const [vehicles, setVehicles] = useState<any[]>();
  const [maxload, setMaxload] = useState<number>(10000);

  const [vehicleFilter, setVehicleFilter] = useState<string>('truck');

  const [parsedPath, setParsedPath] = useState<any>();

  const [returnload, setReturnload] = useState<boolean>(false);

  const onSubmit = async (formValues: any) => {
    if (!vehicles) return;
    if (!scenarioRoute) {
      setNoRouteError(true);
      return;
    }
    let selected_vehicles: any[] = selectedVehicles(formValues.model);
    let request: MultipleRoadTransportRequest = {
      route: scenarioRoute,
      load_in_kg: formValues.load * 1000,
      returntrip: returnload,
      returnload_kg: formValues.returnload * 1000,
      notes: formValues.notes,
      name: formValues.scenarioName,
      project_id: projectID,
      encoded_path: JSON.stringify(scenarioRoute.waypoints),
      vehicles: JSON.stringify(selected_vehicles),
    };
    let response = await runMultipleRoadScenarios(request);
    if (!response || response.result === 'error') {
      setError(true);
    } else {
      navigate('/route-planning-overview', { state: { results: response, projectID: projectID, projectName: formValues.scenarioName } });
    }
  };

  let initialValues = {
    scenarioName: '',
    startPoint: '',
    endPoint: '',
    vehicleType: 'truck',
    notes: '',
    load: '',
    returnload: '',
    hasReturnload: false,
    model: [],
  };

  const validationSchema = Yup.object({
    scenarioName: Yup.string().required('You need to provide a scenario name'),
    load: Yup.number()
      .typeError('Load must be a number')
      .min(0, 'Load must be a positive number')
      .max(maxload, `The smallest maximum load capacity for the selected vehicles is ${maxload} tons`)
      .required('You need to provide a typical load for this scenario'),
    hasReturnload: Yup.boolean(),
    returnload: Yup.number().when('hasReturnload', {
      is: true,
      then: Yup.number()
        .typeError('Load must be a number')
        .min(0, 'Load must be a positive number')
        .max(maxload, `The smallest maximum load capacity for the selected vehicles is ${maxload} tons`)
        .required('You need to provide a return load'),
    }),

    model: Yup.array().required('You need to select vehicles').min(2, 'You need to select at least two vehicles'),
  });

  function selectedVehicles(models: any) {
    let selected_vehicles: any[] = [];
    // eslint-disable-next-line array-callback-return
    models.map((model: any) => {
      selected_vehicles.push(vehicles?.find((v) => v.model === model));
    });
    return selected_vehicles;
  }

  function newRoute(route: Route, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) {
    setScenarioRoute(route);
    setNoRouteError(false);
    setFieldValue('scenarioName', route.scenarioName);
    setFieldValue('startPoint', route.startPoint.name);
    setFieldValue('endPoint', route.endPoint.name);
  }

  function handleReset(_values: any, _formProps: any) {
    setMapKey(Math.round(Math.random() * 100)); //change to update state & force re-render of map .
    setScenarioRoute(null);
    setError(false);
  }

  useEffect(() => {
    getVehicles().then((vehicles: any) => {
      setVehicles(vehicles);
    });
    setParsedPath(encodedPath ? JSON.parse(encodedPath) : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function filterVehicles(filter: string, setFieldValue: any) {
    setFieldValue('model', []);
    setMaxload(10000);
    return setVehicleFilter(filter);
  }

  function vehicleList(type: string) {
    return (
      <>
        {vehicles &&
          vehicles?.length > 0 &&
          vehicles
            .filter((vehicle: any) => vehicle.type.toLowerCase() === type)
            .map((vehicle, index) => (
              <Field as={Checkbox} name='model' key={index} value={vehicle.model}>
                {vehicle.model}
              </Field>
            ))}
      </>
    );
  }

  function setValidLoad(formValues: any) {
    const vehicles = selectedVehicles(formValues.model);
    let load = 0;
    if (vehicles.length > 1) {
      load = vehicles.reduce((a, b) => {
        return a.max_load_kg < b.max_load_kg ? a.max_load_kg : b.max_load_kg;
      });
    } else if ((vehicles.length = 1)) {
      load = vehicles[0]?.max_load_kg || 0;
    }
    setMaxload(load / 1000);
  }

  function toggleReturnLoad() {
    setReturnload(!returnload);
  }

  return (
    <OneColumnLayout
      sectionLabel='Create Scenario'
      sectionHeading='Road Transport Comparison'
      sectionIntro='Compare multiple vehicles for a single route. Click in the map to set waypoints and mark the route. You can drag markers to be as precise as
      possible. You may rename scenario, start and end points. The calculated route will use the the shortest route respecting the order of waypoints.'
      breadCrumbs={breadCrumbs}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width='full'>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} onReset={handleReset} enableReinitialize>
          {({ handleSubmit, values, errors, setFieldValue }) => (
            <>
              <AspectRatio ratio={16 / 9}>
                <Map onRouteChange={(route) => newRoute(route, setFieldValue)} key={mapKey} encodedPath={parsedPath} />
              </AspectRatio>

              <Box borderWidth='1px' rounded='lg' shadow='1px 1px 3px rgba(0,0,0,0.3)' p={6} m='10px auto' as='form' onSubmit={handleSubmit as any}>
                <InputControl name='scenarioName' label='Scenario Name' />

                <InputControl name='startPoint' label='Starting point' mt={4} />
                <InputControl name='endPoint' label='End point' mt={4} />

                <RadioGroupControl name='vehicleType' label='Vehicle Type' mt={4}>
                  <Radio value='truck' onChange={() => filterVehicles('truck', setFieldValue)}>
                    Truck
                  </Radio>
                  <Radio value='bus' onChange={() => filterVehicles('bus', setFieldValue)}>
                    Bus
                  </Radio>
                  <Radio value='car' onChange={() => filterVehicles('car', setFieldValue)}>
                    Car
                  </Radio>
                </RadioGroupControl>

                <CheckboxContainer name='model' label='Vehicle Models' mt={4}>
                  <>
                    {vehicleFilter === 'truck' && vehicleList('truck')}
                    {vehicleFilter === 'bus' && vehicleList('bus')}
                    {vehicleFilter === 'car' && vehicleList('car')}
                  </>
                </CheckboxContainer>

                <InputControl name='load' label='Typical load (tons)' mt={4} />

                <SwitchControl
                  id='hasReturnload'
                  name='hasReturnload'
                  label='Return trip'
                  labelProps={{ textAlign: 'right' }}
                  onChange={toggleReturnLoad}
                  colorScheme='teal'
                  mt={4}
                />

                {returnload && <InputControl name='returnload' label='Return load (tons)' mt={4} />}
                <TextareaControl name='notes' label='Additional notes' helperText='Optional - if you need to note down something about this scenario.' mt={4} />

                {noRouteError && <NoRouteError />}

                <ButtonGroup mt={4}>
                  <SubmitButton loadingText='Calculating.. this could take some time'>Run Scenario</SubmitButton>
                  <ResetButton>Reset</ResetButton>
                </ButtonGroup>
              </Box>
              <ValueChangeListener onChangeValues={setValidLoad} />
            </>
          )}
        </Formik>
        {error && <BadRouteError />}
      </SimpleGrid>
    </OneColumnLayout>
  );
}
