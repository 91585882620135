import { Flex, Text } from '@chakra-ui/react';
import { SimulationResult } from '../../../types/SimulationResult';
import { formatNumber } from '../../../utils/formatters';

interface EnergyUseSummaryProps {
  simulations: SimulationResult[];
}

export default function EnergyUseSummary(props: EnergyUseSummaryProps) {
  const { simulations } = props;

  function getTotal() {
    let total = simulations.map((sim) => {
      return +sim.calculated_values.energy_use_annual;
    });

    return total.reduce((a, b) => {
      return a + b;
    }, 0);
  }

  return (
    <Flex justifyContent={'center'} alignItems={'center'} direction={'column'}>
      <Text fontSize={'3xl'} fontWeight={'bold'} mt={10}>
        {formatNumber(getTotal())} kWh
      </Text>
      <Text fontSize={'xl'}>Total project energy use</Text>
    </Flex>
  );
}
