import { VStack, Text, Heading } from '@chakra-ui/react';
import OneColumnLayout from '../layout/OneColLayout';

function Insights() {
  return (
    <OneColumnLayout
      sectionLabel='Insights'
      sectionHeading='Learn more about how you saved CO2 and cost using Seidr.AI'
      sectionIntro='Compare and investigate your CO2 reductions'
    >
      <VStack>
        <Heading>Coming Soon!</Heading>
        <Text>This page will give you the tools to investigate which projects gave the best cost per tonne reduction as well as other key metrics</Text>
      </VStack>
    </OneColumnLayout>
  );
}

export default Insights;
