import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Icon,
  Spinner,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { MdDeleteForever } from 'react-icons/md';

interface AlertProps {
  onConfirm: () => void;
}

export const Alert = (props: AlertProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clicked, setClicked] = useState<boolean>(false);

  function onConfirm() {
    setClicked(true);
    onClose();
    props.onConfirm();
  }

  const btnRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button onClick={onOpen}>{!clicked ? <Icon color={'red'} as={MdDeleteForever} /> : <Spinner size={'sm'} />}</Button>

      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={btnRef}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Warning: This result set is about to be deleted. Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={btnRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={onConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
