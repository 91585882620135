import * as React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Login from './components/login/Login';
import Register from './components/login/Register';
import Reset from './components/login/Reset';
import Dashboard from './components/dashboard/Dashboard';
import CreateProject from './components/project/CreateProject';
import AccountingOverview from './components/project/AccountingOverview';
import RoadTransportScenario from './components/scenario/road/RoadTransport';
import SeaTransportScenario from './components/scenario/sea/SeaTransport';
import Projects from './components/project/Projects';
import ScenarioResult from './components/scenario/sea/ScenarioResult';
import RoadScenarioResult from './components/scenario/road/ScenarioResult';
import Insights from './components/dashboard/Insights';
import About from './components/dashboard/About';
import Contact from './components/dashboard/Contact';

import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firestore/firebaseConfig';
import CompareAccounting from './components/scenario/comparison/CompareAccounting';
import CompareRoutePlanning from './components/scenario/comparison/CompareRoutePlanning';
import BuildingScenario from './components/scenario/building/Building';
import BuildingResult from './components/scenario/building/ScenarioResult';
import ElectricityConsumer from './components/scenario/consumer/ElectricityConsumer';
import ElectricitySupplier from './components/scenario/supplier/ElectricitySupplier';
import SolarScenario from './components/scenario/solar/Solar';
import SolarResult from './components/scenario/solar/ScenarioResult';
import RoadComparisonScenario from './components/scenario/road/RoadComparison';
import RoutePlanningOverview from './components/project/RoutePlanningOverview';

function App() {
  const [user, loading] = useAuthState(auth);

  return (
    <Router>
      <ChakraProvider theme={theme}>
        <Routes>
          {!user && (
            <>
              <Route path='/' element={<Login />} />
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/reset' element={<Reset />} />
            </>
          )}
          {!user && !loading && <Route path='*' element={<Navigate to='/login' />} />}

          {user && !loading && (
            <>
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/project/create' element={<CreateProject />} />
              <Route path='/project' element={<AccountingOverview />} />
              <Route path='/projects' element={<Projects />} />
              <Route path='/road-transport-scenario' element={<RoadTransportScenario />} />
              <Route path='/route-planning-scenario' element={<RoadComparisonScenario />} />
              <Route path='/route-planning-overview' element={<RoutePlanningOverview />} />
              <Route path='/road-transport-result' element={<RoadScenarioResult />} />
              <Route path='/sea-transport-scenario' element={<SeaTransportScenario />} />
              <Route path='/sea-transport-result' element={<ScenarioResult />} />
              <Route path='/building-scenario' element={<BuildingScenario />} />
              <Route path='/building-result' element={<BuildingResult />} />
              <Route path='/electricity-consumer' element={<ElectricityConsumer />} />
              <Route path='/electricity-supplier' element={<ElectricitySupplier />} />
              <Route path='/solar-scenario' element={<SolarScenario />} />
              <Route path='/solar-result' element={<SolarResult />} />
              <Route path='/insights' element={<Insights />} />
              <Route path='/compare_routes' element={<CompareRoutePlanning />} />
              <Route path='/compare' element={<CompareAccounting />} />
              <Route path='*' element={<Navigate to='/projects' />} />
            </>
          )}
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </ChakraProvider>
    </Router>
  );
}
export default App;
