import { VStack, Text, Heading } from '@chakra-ui/react';
import OneColumnLayout from '../layout/OneColLayout';
import ReactPlayer from 'react-player';

function About() {
  return (
    <OneColumnLayout
      sectionLabel='About'
      sectionHeading='Learn more about the Seidr.AI technology'
      sectionIntro='Seidr.AI builds upon years of development and research with large industry partners'
    >
      <VStack>
        <Heading>What is Seidr?</Heading>

        <ReactPlayer url='videos/Seidr.AI.mp4' controls={true} />
        <Text>
          Seidr builds on years of development and research within new technologies in the transport sector. Our goal is to utilize a physics based approach
          with AI to allow you to make data driven decisions.
        </Text>
      </VStack>
    </OneColumnLayout>
  );
}

export default About;
