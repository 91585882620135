import { SimpleGrid, Heading, Stack, GridItem, Box, Spinner, AspectRatio } from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getSimulationsSummarised } from '../../firestore/simulations';
import { BreadCrumbInfo } from '../../types/BreadCrumbInfo';
import { ProjectType } from '../../types/ProjectType';
import { SimulationResult } from '../../types/SimulationResult';
import { formatNumber } from '../../utils/formatters';
import OneColumnLayout from '../layout/OneColLayout';
import Simulations from './components/Simulations';
import Map from '../scenario/road/components/map/Map';
import { DownloadLink } from '../scenario/comparison/components/DownloadLink';
import { generateTabledata } from '../scenario/road/components/Dataformatter';

interface RoutePlanningProjectState {
  results: any[];
  projectName: any;
  projectID: string;
}

export default function RoutePlanningOverview() {
  const { state } = useLocation();
  const breadCrumbs: BreadCrumbInfo[] = [{ to: '/projects', displayText: 'Projects' }];

  const { results, projectID, projectName } = state as RoutePlanningProjectState;

  const [simulations, setSimulations] = useState<SimulationResult[]>(results);
  const [downloadable, setDownloadable] = useState<any>([{}]);

  useEffect(() => {
    if (results === undefined) {
      loadSimulations();
    } else {
      setDownloadable(
        generateTabledata(
          results.map((sim) => {
            return { ...sim, active: true };
          })
        )
      );
    }
    //load simulations is not a real dependency, and it would cause endless re-render ot include it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadSimulations() {
    getSimulationsSummarised(projectID)?.then((simulations) => {
      let sims = simulations as SimulationResult[];
      setSimulations(_.sortBy(sims, 'name'));
      setDownloadable(
        generateTabledata(
          sims.map((sim) => {
            return { ...sim, active: true };
          })
        )
      );
    });
  }

  function calculateEmissions(result: SimulationResult): any {
    return (result.result_summary.emissions_co2_g / result.result_summary.route_length_m / result.result_summary.total_load_kg) * 1000 * 1000;
  }

  function getFuelCost(result: SimulationResult) {
    const vehicle = JSON.parse(result.inputs.vehicle);

    if (vehicle.drivetrain.toLowerCase() === 'diesel') {
      return formatNumber((result.result_summary.fuel_price_unit * result.result_summary.fuel_consumption_unit) / 1000);
    }
    if (vehicle.drivetrain.toLowerCase() === 'electric') {
      return formatNumber(
        result.result_summary.slow_charge_price_kwh * result.result_summary.energy_consumption_kWh_km * (result.result_summary.route_length_m / 1000)
      );
    }
  }

  function createIntro() {
    if (simulations) {
      let returnLoad = simulations[0].inputs.returnload_kg ? ` and a return load of ${simulations[0].inputs.returnload_kg / 1000} tons.` : ` (no return).`;
      return `Route planning for "${projectName}" totalling ${formatNumber(simulations[0].result_summary.route_length_m / 1000)} km with a load of ${
        simulations[0].inputs.load_in_kg / 1000
      } tons${returnLoad}`;
    }
    return '';
  }

  return (
    <OneColumnLayout sectionLabel='Route Planning Overview' sectionHeading={projectName} sectionIntro={createIntro()} breadCrumbs={breadCrumbs}>
      {simulations && (
        <AspectRatio ratio={16 / 9} maxH={400}>
          <Map onRouteChange={() => {}} encodedPath={JSON.parse(simulations[0].inputs.encoded_path)} displayOnly={true}></Map>
        </AspectRatio>
      )}
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6} width='full' p={4}>
        <GridItem h={150}>
          <Heading size={'lg'}>Total CO2</Heading>
          <Box border='1px solid #f0f0f0' p={4} pt={2} mt={2}>
            {simulations?.map((result, index) => {
              return (
                <Box key={index}>
                  {result.name}: <b>{formatNumber(result.result_summary.emissions_co2_g / 1000)} kg</b>
                </Box>
              );
            })}
          </Box>
        </GridItem>
        <GridItem>
          <Heading size={'lg'}>CO2 per ton/km</Heading>
          <Box border='1px solid #f0f0f0' p={4} pt={2} mt={2}>
            {simulations?.map((result, index) => {
              return (
                <Box key={index}>
                  {result.name}: <b>{formatNumber(calculateEmissions(result))} g</b>
                </Box>
              );
            })}
          </Box>
        </GridItem>
        <GridItem>
          <Heading size={'lg'}>Fuel cost </Heading>
          <Box border='1px solid #f0f0f0' p={4} pt={2} mt={2}>
            {simulations?.map((result, index) => {
              return (
                <Box key={index}>
                  {result.name}: <b>{getFuelCost(result)} kr</b>
                </Box>
              );
            })}
          </Box>
        </GridItem>
        <GridItem>
          <Heading size={'lg'}>Charging stops</Heading>
          <Box border='1px solid #f0f0f0' p={4} pt={2} mt={2}>
            {simulations?.map((result, index) => {
              return (
                <Box key={index}>
                  {result.name}: <b>{formatNumber(result.result_summary.estimated_charges)}</b> <br />
                </Box>
              );
            })}
          </Box>
        </GridItem>
      </SimpleGrid>

      <Stack mt={20} p={4}>
        <Heading size='md'>Scenario details</Heading>
        <DownloadLink data={downloadable.data} headings={downloadable.headings} title={'Download all'} />
        {simulations ? (
          <Simulations
            simulations={simulations}
            projectID={projectID}
            projectName={projectName}
            projectType={ProjectType.RoutePlanning}
            onDelete={loadSimulations}
          />
        ) : (
          <Box width={'full'} display={'flex'}>
            <Spinner p={6} margin={'auto'} size='xl' />
          </Box>
        )}
      </Stack>
    </OneColumnLayout>
  );
}
