import { Table, TableCaption, Tbody, Td, Tr, Th, Thead } from '@chakra-ui/react';
import opsProfiles from '../inputs/opsprofiles.json';
import vessels from '../inputs/vessels.json';
import { SimulationResult } from '../../../../types/SimulationResult';
import { formatNumber } from '../../../../utils/formatters';

interface RunParametersProps {
  simulationResult: SimulationResult;
}

export default function RunParameters(props: RunParametersProps) {
  const { distance_nm, profile_nr, speed_knot } = props.simulationResult.result_summary;
  const { Length, Width, ship_id } = props.simulationResult.result_summary.ship;

  return (
    <Table variant='striped' colorScheme='teal' mt={4}>
      <TableCaption></TableCaption>
      <Thead>
        <Tr>
          <Th>Parameters:</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>Ship length:</Td>
          <Td isNumeric>{formatNumber(Length)} meters</Td>
        </Tr>
        <Tr>
          <Td>Ship breadth:</Td>
          <Td isNumeric>{formatNumber(Width)} meters</Td>
        </Tr>
        <Tr>
          <Td>Distance:</Td>
          <Td isNumeric>{formatNumber(distance_nm)} NM</Td>
        </Tr>
        <Tr>
          <Td>Vessel type:</Td>
          <Td isNumeric>{vessels.vessels.find((v) => v.id === ship_id)?.type}</Td>
        </Tr>
        <Tr>
          <Td>Pattern of usage:</Td>
          <Td isNumeric>{opsProfiles.profiles.find((i) => i.id === profile_nr)?.name}</Td>
        </Tr>
        <Tr>
          <Td>Transit speed:</Td>
          <Td isNumeric>{formatNumber(speed_knot)} knots</Td>
        </Tr>
      </Tbody>
    </Table>
  );
}
