import { SimpleGrid } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ScenarioType } from '../../../types/ScenarioType';
import ComponentButton from './ComponentButton';

interface ComponentsProps {
  projectID: string;
  modules: string[];
}

export default function Components(props: ComponentsProps) {
  const navigate = useNavigate();

  const { modules, projectID } = props;

  function goToScenario(url: string) {
    navigate(url, { state: { projectID: projectID } });
  }
  return (
    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={2} width='full' p={4} style={{ gridAutoRows: '1fr' }}>
      {modules?.includes(ScenarioType.RoadTransport) && (
        <ComponentButton
          title='Road Transport Simulation'
          module={ScenarioType.RoadTransport}
          description='Simulate emissions and energy use for a road transport scenario such as a bus route, truck transport or car trip.'
          onClick={() => goToScenario('/road-transport-scenario')}
        />
      )}
      {modules?.includes(ScenarioType.SeaTransport) && (
        <ComponentButton
          title='Sea Transport Simulation'
          module={ScenarioType.SeaTransport}
          onClick={() => goToScenario('/sea-transport-scenario')}
          description='Simulate emissions and energy use for vessels such as ferries, fishing boats and cargo ships.'
        />
      )}
      {modules?.includes(ScenarioType.Building) && (
        <ComponentButton
          title='Building simulation'
          module={ScenarioType.Building}
          onClick={() => goToScenario('/building-scenario')}
          description='Simulate energy use and emissions for a building such as an office, factory or high rise building.'
        />
      )}
      {modules?.includes(ScenarioType.Solar) && (
        <ComponentButton
          title='Solar Plant'
          module={ScenarioType.Solar}
          onClick={() => goToScenario('/solar-scenario')}
          description='Simulate energy output from a solar plant or solar panel installation.'
        />
      )}
      {modules?.includes(ScenarioType.ElectricityConsumer) && (
        <ComponentButton
          title='Other electricity consumer'
          module={ScenarioType.ElectricityConsumer}
          onClick={() => goToScenario('/electricity-consumer')}
          description='Add an entity to your project that consumes energy. Use this for pre-existing installations, buildings etc where you already have the numbers.'
        />
      )}

      {modules?.includes(ScenarioType.ElectricitySupplier) && (
        <ComponentButton
          title='Other electricity supplier'
          module={ScenarioType.ElectricitySupplier}
          onClick={() => goToScenario('/electricity-supplier')}
          description='Add an entity to your project that produce or deliver energy. Use this for pre-existing installations where you already have the numbers and energy supply points that are not otherwise part of the project.'
        />
      )}
    </SimpleGrid>
  );
}
