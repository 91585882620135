import React, { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firestore/firebaseConfig';

function UserInfo() {
  const [user] = useAuthState(auth);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (user) return <>Logged in as {user?.email}</>;
  return <></>;
}
export default UserInfo;
