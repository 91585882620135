import { httpsCallable } from 'firebase/functions';
import { functions } from './firebaseConfig';

const getVehicles = async () => {
  try {
    const getVehicles = httpsCallable(functions, 'getVehicles');
    let response = await getVehicles();
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export { getVehicles };
