import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getSimulationsSummarised } from '../../../firestore/simulations';
import { BreadCrumbInfo } from '../../../types/BreadCrumbInfo';
import { ScenarioType } from '../../../types/ScenarioType';
import { SimulationResult } from '../../../types/SimulationResult';
import OneColumnLayout from '../../layout/OneColLayout';
import ScenarioInfoBuilding from './ScenarioInfoBuilding';
import ScenarioInfoRoad from './ScenarioInfoRoad';
import ScenarioInfoSea from './ScenarioInfoSea';
import SimulationsList from './SimulationsList';

export default function CompareAccounting() {
  const { state } = useLocation();
  const { projectID } = state as any;

  const breadCrumbs: BreadCrumbInfo[] = [
    { to: '/projects', displayText: 'Projects' },
    { to: '/project', state: { projectID: projectID }, displayText: 'Project overview' },
  ];

  const [simulations, setSimulations] = useState<SimulationResult[]>([]);
  const [roadScenarios, setRoadScenarios] = useState<SimulationResult[]>([]);
  const [seaScenarios, setSeaScenarios] = useState<SimulationResult[]>([]);
  const [buildingScenarios, setBuildingScenarios] = useState<SimulationResult[]>([]);
  const [activeScenarios, setActiveScenarios] = useState<any>([]);

  useEffect(() => {
    if (simulations.length === 0) {
      getSimulationsSummarised(projectID)?.then((result) => {
        if (result) {
          let simulations = result as SimulationResult[];
          setSimulations(
            simulations?.map((sim) => {
              return { ...sim, active: false };
            })
          );

          let actives = simulations?.map((sim) => {
            return { id: sim.id, active: false };
          });
          setActiveScenarios(actives);
          let road_sims: any = simulations?.filter((sim) => sim.type === ScenarioType.RoadTransport);
          setRoadScenarios(road_sims);

          let sea_sims: any = simulations?.filter((sim) => sim.type === ScenarioType.SeaTransport);
          setSeaScenarios(sea_sims);

          let build_sims: any = simulations?.filter((sim) => sim.type === ScenarioType.Building);
          setBuildingScenarios(build_sims);
        }
      });
    }
  }, [activeScenarios, projectID, simulations]);

  function toggleScenario(scenario_id: string) {
    let active = activeScenarios;
    let itemIndex = active.findIndex((i: { id: string }) => i.id === scenario_id);
    active[itemIndex].active = !active[itemIndex].active;
    setActiveScenarios([...active]); //react being an ass. make sure the actual reference is changing and not just the value

    let scenario = simulations.find(({ id }) => id === scenario_id);
    if (scenario) {
      scenario.active = !scenario.active;
    }
    setSimulations([...simulations]); //react being an ass. make sure the actual reference is changing and not just the value
  }

  return (
    <OneColumnLayout
      sectionLabel='Compare'
      sectionHeading={'Compare Scenarios'}
      sectionIntro={'Select a scenario type to compare, then choose the scenarios you want to see'}
      breadCrumbs={breadCrumbs}
    >
      <>
        <Tabs variant='enclosed' colorScheme='teal'>
          <TabList>
            <Tab>Sea transport scenarios</Tab>
            <Tab>Road transport scenarios</Tab>
            <Tab>Building scenarios</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SimulationsList onShowSimulation={toggleScenario} simulations={seaScenarios} type={ScenarioType.SeaTransport}></SimulationsList>
              <ScenarioInfoSea seaScenarios={seaScenarios} activeScenarios={activeScenarios} />
            </TabPanel>
            <TabPanel>
              <SimulationsList onShowSimulation={toggleScenario} simulations={roadScenarios} type={ScenarioType.RoadTransport}></SimulationsList>
              <ScenarioInfoRoad roadScenarios={simulations?.filter((sim) => sim.type === ScenarioType.RoadTransport)} />
            </TabPanel>
            <TabPanel>
              <SimulationsList onShowSimulation={toggleScenario} simulations={buildingScenarios} type={ScenarioType.Building}></SimulationsList>
              <ScenarioInfoBuilding scenarios={buildingScenarios} activeScenarios={activeScenarios} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    </OneColumnLayout>
  );
}
