import { SimpleGrid, AspectRatio, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import { Box, ButtonGroup, Radio } from '@chakra-ui/react';
import { Formik } from 'formik';
import { InputControl, RadioGroupControl, ResetButton, SubmitButton, TextareaControl } from 'formik-chakra-ui';
import * as Yup from 'yup';
import Map from './components/map/Map';
import { runSolarScenario } from '../../../firestore/simulations';
import { useState } from 'react';
import { RoutePoint } from '../../../types/RoutePoint';
import OneColumnLayout from '../../layout/OneColLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { BreadCrumbInfo } from '../../../types/BreadCrumbInfo';
import { SolarRequest } from '../../../types/SolarRequest';

interface SolarScenarioState {
  projectID: string;
  encodedPath?: any;
}
export default function SolarScenario() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { projectID } = state as SolarScenarioState;

  const breadCrumbs: BreadCrumbInfo[] = [
    { to: '/projects', displayText: 'Projects' },
    { to: '/project', state: { projectID: projectID }, displayText: 'Project overview' },
  ];

  const [mapKey, setMapKey] = useState<number>(1); //hack to force map to re-render on reset
  const [lat, setLat] = useState<number>(60.84);
  const [lng, setLng] = useState<number>(8.24);
  const [error, setError] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    let request: SolarRequest = {
      lat: lat,
      lng: lng,
      roof_area_m2: values.roof_m2,
      efficiency_factor_percent: values.efficiency_factor_percent,
      notes: values.notes,
      name: values.scenarioName,
      project_id: projectID,
    };

    let result = await runSolarScenario(request);
    if (!result || result.result === 'error') {
      setError(true);
    } else {
      navigate('/solar-result', { state: { ...result } });
    }
  };

  let initialValues = {
    scenarioName: '',
    efficiency_factor_percent: '0.19',
    roof_m2: '',
  };

  const validationSchema = Yup.object({
    scenarioName: Yup.string().required('You need to provide a scenario name'),
    roof_m2: Yup.number().typeError('Available area must be a number').required('Available area is required'),
  });

  function handleReset(_values: any, _formProps: any) {
    setMapKey(Math.round(Math.random() * 100)); //change to update state & force re-render of map .
    setError(false);
  }

  function setPoint(point: RoutePoint) {
    setLat(point.latLng.lat());
    setLng(point.latLng.lng());
  }

  return (
    <OneColumnLayout
      sectionLabel='Create Scenario'
      sectionHeading='New Solar Plant scenario'
      sectionIntro='Create a simulation for the energy from a solar plant (solar panel installation). Click in the map to set the location 
      of the installation and be as accurate as you can with the placement of the installation area. The installation area will 
      usually be a roof, but can also be a field or other ground area.'
      breadCrumbs={breadCrumbs}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width='full'>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} onReset={handleReset} enableReinitialize>
          {({ handleSubmit, values, errors, setFieldValue }) => (
            <>
              <AspectRatio ratio={16 / 9}>
                <Map onSetPoint={(point) => setPoint(point)} key={mapKey} />
              </AspectRatio>

              <Box borderWidth='1px' rounded='lg' shadow='1px 1px 3px rgba(0,0,0,0.3)' p={6} m='10px auto' as='form' onSubmit={handleSubmit as any}>
                <InputControl name='scenarioName' label='Scenario Name' />

                <RadioGroupControl name='efficiency_factor_percent' label='Solar panel efficiency' mt={4}>
                  <Radio value='0.13'>Low end</Radio>
                  <Radio value='0.19'>Midrange</Radio>
                  <Radio value='0.23'>High end</Radio>
                </RadioGroupControl>

                <InputControl name='roof_m2' label='Available area m²' mt={4} />

                <TextareaControl name='notes' label='Additional notes' helperText='Optional - if you need to note down something about this scenario.' mt={4} />
                <ButtonGroup mt={4}>
                  <SubmitButton loadingText='Calculating.. this could take some time'>Run Scenario</SubmitButton>
                  <ResetButton>Reset</ResetButton>
                </ButtonGroup>
              </Box>
            </>
          )}
        </Formik>
        {error && (
          <Alert status='error'>
            <AlertIcon />
            <AlertDescription>
              An error occured whilst running the calculations for the solar plant. If the problem persists, please contact us.
            </AlertDescription>
          </Alert>
        )}
      </SimpleGrid>
    </OneColumnLayout>
  );
}
