import { collection, query, getDocs, where, doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { ProjectType } from '../types/ProjectType';
import { auth, db, functions } from './firebaseConfig';

const getProjects = async (user_id: string) => {
  const q = query(collection(db, 'projects'), where('user_id', '==', user_id));
  return await getDocs(q)
    .then((snapshot) => {
      let projects: any[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        const id = doc.id;
        projects.push({ ...data, id });
      });
      return projects;
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

const getProject = async (project_id: string) => {
  const docRef = doc(db, 'projects', project_id);
  return await getDoc(docRef).then((projectSnap) => {
    return projectSnap.data();
  });
};

const createProject = async (name: string, modules: string[], project_type: ProjectType) => {
  if (auth.currentUser) {
    try {
      const createProject = httpsCallable(functions, 'createProject');
      let response = await createProject({ name: name, modules: modules, type: project_type, uid: auth.currentUser?.uid });
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
};

const deleteProject = async (project_id: string) => {
  try {
    const deleteProject = httpsCallable(functions, 'deleteProject');
    let response = await deleteProject({ project_id: project_id });
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export { createProject, getProjects, getProject, deleteProject };
