import { useEffect, useState } from 'react';
import { GraphData, GraphPoint } from '../../../../../types/TimeGraphData';
import MinutesGraph from '../../../../layout/graphs/MinutesGraph';

interface EffectGraphProps {
  time_series: any;
}

export default function EffectGraph(props: EffectGraphProps) {
  const { time_series } = props;
  const [graphData, setGraphData] = useState<GraphData>();

  useEffect(() => {
    if (time_series) {
      let data: GraphPoint[] = [];
      let timestamp = 0;

      try {
        for (var index in time_series.duration_s) {
          timestamp += time_series.duration_s[index];
          let effect = time_series.w[index];

          data.push({ x: timestamp / 60, y: effect / 1000 }); // w to kW
        }
      } catch (err) {
        //something wrong in time series but that is okay.
        data = [];
      }

      setGraphData([
        {
          id: 'Power',
          color: 'teal',
          data: data,
        },
      ]);
    }
  }, [time_series]);

  return <>{graphData && <MinutesGraph legendX='Minutes' legendY='kW' data={graphData} heading={'Power'} description={'Power used throughout drive'} />}</>;
}
