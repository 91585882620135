import { Box, Button, Grid, Heading } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getProjects } from '../../firestore/projects';
import { auth } from '../../firestore/firebaseConfig';
import OneColumnLayout from '../layout/OneColLayout';
import ProjectRow from './components/ProjectRow';

export default function ProjectOverview() {
  const [projects, setProjects] = useState<any[] | null>(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (user && !projects) {
      getProjects(user.uid)?.then((p) => setProjects(p));
    }
  }, [projects, user]);

  let intro =
    projects && projects.length > 0
      ? 'This page shows all the projects you have access to. Click on one of the projects below to see more details, run new simulations or add data sets.'
      : 'Get started by adding a project';

  return (
    <OneColumnLayout sectionLabel='All projects' sectionHeading='My projects' sectionIntro={intro}>
      <Box maxW={1200} mr={12}>
        <Grid mb={6} templateColumns='4fr 1fr 2fr' gap={6}>
          <Box>
            <Heading size={'sm'}> Project Name</Heading>
          </Box>
          <Box>
            <Heading size={'sm'}> Modules</Heading>
          </Box>
          <Box></Box>
        </Grid>
        {projects?.map((project: any) => (
          <ProjectRow key={project.id} project={project} />
        ))}
        <Button as='a' href='/project/create'>
          Create a project
        </Button>
      </Box>
    </OneColumnLayout>
  );
}
