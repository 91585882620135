import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text, ListItem, UnorderedList, Heading } from '@chakra-ui/react';
import { ScenarioType } from '../../../types/ScenarioType';
import { ModuleName } from '../../layout/ModuleName';

export default function ExplainRoutePlanningModules() {
  return (
    <>
      <Accordion allowMultiple mt={4} defaultIndex={[]}>
        <AccordionItem>
          <AccordionButton>
            <ModuleName type={ScenarioType.RoadTransport} text={'Road Transport Module'} />
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Heading size={'sm'}>Usage</Heading>
            <Text>
              The road transport module allows you to simulate the operation of road going vehicles. This enables you to calculate and compare parameters such
              as:
            </Text>
            <UnorderedList ml='10'>
              <ListItem>Energy consumption</ListItem>
              <ListItem>CO2 emissions</ListItem>
              <ListItem>Charging need</ListItem>
              <ListItem>Energy cost</ListItem>
              <ListItem>Total Cost of Ownership</ListItem>
            </UnorderedList>
            <Heading size={'sm'}>Technology</Heading>
            <Text>It utilizes Seidr.AIs physics based model combined with AI to account for complex parameters such as elevation, traffic and weather.</Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <ModuleName type={ScenarioType.SeaTransport} text={'Sea Transport Module'} />
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Heading size={'sm'}>Usage</Heading>
            <Text>
              The sea transport module allows you to simulate the operation of seagoing vessels, with a primary focus on freight, ferries, fisheries and
              aquaculture. This enables you to calculate and compare parameters such as:
            </Text>
            <UnorderedList ml='10'>
              <ListItem>Energy consumption</ListItem>
              <ListItem>CO2 emissions</ListItem>
              <ListItem>Charging need</ListItem>
              <ListItem>Energy cost</ListItem>
              <ListItem>Total Cost of Ownership</ListItem>
            </UnorderedList>
            <Heading size={'sm'}>Technology</Heading>
            <Text>It utilizes Seidr.AIs physics based model combined with AI to account for complex parameters such as waves, current and wind.</Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}
