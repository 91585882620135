import { SimpleGrid } from '@chakra-ui/react';
import { Box, ButtonGroup } from '@chakra-ui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { InputControl, ResetButton, SubmitButton, TextareaControl } from 'formik-chakra-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { BreadCrumbInfo } from '../../../types/BreadCrumbInfo';
import { saveSimulation } from '../../../firestore/simulations';
import TwoColumnLayout from '../../layout/TwoColLayout';
import { ScenarioType } from '../../../types/ScenarioType';

interface ScenarioState {
  projectID: string;
  encodedPath?: string;
}

export default function ElectricitySupplier() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { projectID } = state as ScenarioState;

  const breadCrumbs: BreadCrumbInfo[] = [
    { to: '/projects', displayText: 'Projects' },
    { to: '/project', state: null, displayText: 'Project overview' },
  ];

  const onSubmit = async (values: any) => {
    await saveSimulation({
      project_id: projectID,
      name: values.name,
      inputs: {
        energy_use_annual: -values.energy_use_annual_kwh,
        co2_emissions_annual: -values.co2_emissions_annual,
        total_cost_of_ownership: -values.total_cost_of_ownership,
      },
      type: ScenarioType.ElectricitySupplier,
      calculated_values: {
        energy_use_annual: -values.energy_use_annual_kwh,
        co2_emissions_annual: -values.co2_emissions_annual,
        total_cost_of_ownership: -values.total_cost_of_ownership,
      },
      notes: values.notes,
      result_summary: null,
      time_series: null,
    });
    navigate('/project', { state: { projectID: projectID } });
  };

  const initialValues = {
    name: '',
    energy_use_annual_kwh: '',
    co2_emissions_annual: '',
    total_cost_of_ownership: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('You need to provide a name'),
    energy_use_annual_kwh: Yup.number().typeError('Annual energy supplied must be a number').required('Annual energy supplied is required'),
    co2_emissions_annual: Yup.number().typeError('CO₂ emissions must be a number').required('CO₂ emissions is required'),
    total_cost_of_ownership: Yup.number().typeError('Cost must be a number').required('Cost is required'),
  });

  function handleReset(_values: any, _formProps: any) {}

  return (
    <TwoColumnLayout
      sectionLabel='Add supplier'
      sectionHeading='New Electricity Supplier'
      sectionIntro='Use this form to add any installation or power supply point that delivers electricity to your project, where you already know the numbers.'
      breadCrumbs={breadCrumbs}
      left={
        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10} width='full'>
          <Formik initialValues={initialValues} onSubmit={onSubmit} onReset={handleReset} validationSchema={validationSchema}>
            {({ handleSubmit }) => (
              <>
                <Box borderWidth='1px' rounded='lg' shadow='1px 1px 3px rgba(0,0,0,0.3)' p={6} m='10px auto' as='form' onSubmit={handleSubmit as any}>
                  <InputControl name='name' label='Name' />

                  <InputControl name='energy_use_annual_kwh' label='Annual delivered energy (kWh)' />
                  <InputControl name='co2_emissions_annual' label='Annual CO₂ emissions (kg)' />
                  <InputControl name='total_cost_of_ownership' label='Total cost of ownership (kr)' />

                  <TextareaControl
                    name='notes'
                    label='Additional notes'
                    helperText='Optional - if you need to note down something about this scenario.'
                    mt={4}
                  />

                  <ButtonGroup mt={4}>
                    <SubmitButton loadingText=''>Save</SubmitButton>
                    <ResetButton>Reset</ResetButton>
                  </ButtonGroup>
                </Box>
              </>
            )}
          </Formik>
        </SimpleGrid>
      }
      right={<></>}
    />
  );
}
