import { HStack, VStack, Text, Image, Divider, Link } from '@chakra-ui/react';
import OneColumnLayout from '../layout/OneColLayout';
import logo from '../../assets/logo.svg';
import { MdPhoneInTalk, MdOutlineEmail } from 'react-icons/md';
import { IoMdGlobe } from 'react-icons/io';
//import { Link } from "react-router-dom";

function Contact() {
  return (
    <OneColumnLayout sectionLabel='Contact' sectionHeading='Want to know more? ' sectionIntro=''>
      <VStack>
        <Image src={logo} height='10vh' align='center' mr='4'></Image>
        <HStack height='5vh'>
          <IoMdGlobe size='2vh'></IoMdGlobe>
          <Link href='https://seidr.ai' isExternal fontSize={14} ml='5'>
            Visit our website
          </Link>
          <Divider orientation='vertical'></Divider>
          <MdPhoneInTalk size='2vh' />

          <Text fontSize={14} ml='5'>
            47 60 28 48
          </Text>
          <Divider orientation='vertical'></Divider>
          <MdOutlineEmail size='2vh' />

          <Link href='mailto:ohm@seidr.ai' isExternal fontSize={14} ml='5'>
            Send email
          </Link>
        </HStack>
      </VStack>
    </OneColumnLayout>
  );
}

export default Contact;
