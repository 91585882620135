import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Checkbox, Grid, Heading } from '@chakra-ui/react';
import { SimulationResult } from '../../../types/SimulationResult';
import { ModuleName } from '../../layout/ModuleName';

interface SimulationListProps {
  onShowSimulation: any;
  simulations: SimulationResult[];
  type: string;
}

export default function SimulationsList(props: SimulationListProps) {
  const { onShowSimulation, simulations, type } = props;
  function toggle(simulation: SimulationResult) {
    onShowSimulation(simulation.id);
  }

  return (
    <Accordion allowToggle defaultIndex={[0]}>
      <AccordionItem>
        <AccordionButton>
          <ModuleName type={type} text={'Select scenarios'} />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          {simulations.length > 0 &&
            simulations.map((simulation: SimulationResult) => (
              <Grid mb={6} ml={2} templateColumns='30px 4fr' gap={2} key={simulation.id} maxW={1200}>
                <Box>
                  <Checkbox onChange={() => toggle(simulation)} colorScheme={'teal'}></Checkbox>
                </Box>
                <Box>
                  <Heading size={'sm'}>{simulation.name}</Heading>
                  {simulation.notes && <Box> simulation.notes </Box>}
                </Box>
              </Grid>
            ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
