import { Box, Flex, useColorModeValue, Stack, Image, IconButton } from '@chakra-ui/react';
import { logout } from '../../firestore/authentication';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import logo from '../../assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import FeedbackFloater from './FeedbackFloater';
import { MdLogout } from 'react-icons/md';

export default function Header() {
  const navigate = useNavigate();

  return (
    <>
      <Box bg={useColorModeValue('green.50', 'green.900')} px={4}>
        <Flex alignItems={'center'} justifyContent={'space-between'} py={2}>
          <Image
            src={logo}
            h={'80px'}
            p={2}
            justifySelf='flex-start'
            onClick={() => {
              navigate('/projects');
            }}
            cursor='pointer'
            border={'1px solid teal'}
            borderRadius={20}
            bg={useColorModeValue('white', 'gray.200')}
          />
          <Stack direction={'row'} spacing={7}>
            <FeedbackFloater />
            <ColorModeSwitcher justifySelf='flex-end' />
            <IconButton aria-label='Log out' icon={<MdLogout />} onClick={logout} variant={'outline'} colorScheme={'teal'} isRound={true} />
          </Stack>
        </Flex>
      </Box>
    </>
  );
}
