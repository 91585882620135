import AnnualGraph from '../../../../layout/graphs/AnnualGraph';

interface OutdoorTempGraphProps {
  time_series: any;
}

export default function OutdoorTempGraph(props: OutdoorTempGraphProps) {
  function energyUse() {
    let data: any[] = [];

    let dailyAccumulatedUse = 0;
    let dayCount = 0;

    for (var hourPoint in props.time_series.kWhNeeds) {
      dailyAccumulatedUse += props.time_series.kWhNeeds[hourPoint];
      if (+hourPoint % 24 === 0) {
        dayCount += 1;
        data.push({ x: dayCount, y: dailyAccumulatedUse });
        dailyAccumulatedUse = 0;
      }
    }

    return [
      {
        id: 'Energy',
        color: 'hsl(325, 70%, 50%)',
        data: data,
      },
    ];
  }

  return (
    <AnnualGraph legendY={'KWh'} legendX={'Day'} data={energyUse()} heading={'KWh used'} description={'Energy used by the building over a typical year'} />
  );
}
