import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export function ValueChangeListener(props: any) {
  const { onChangeValues } = props;
  const { values } = useFormikContext();
  useEffect(() => {
    onChangeValues(values);
  }, [onChangeValues, values]);
  return null;
}
