import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SimulationResult } from '../../../types/SimulationResult';
import { generateTabledata } from '../road/components/Dataformatter';
import { DownloadLink } from './components/DownloadLink';

interface ScenarioInfoRoadProps {
  roadScenarios: SimulationResult[];
}

export default function ScenarioInfoRoad(props: ScenarioInfoRoadProps) {
  const [scenarioInfo, setDownloadable] = useState<any>([{}]);

  useEffect(() => {
    setDownloadable(generateTabledata(props.roadScenarios));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <DownloadLink data={scenarioInfo.data} headings={scenarioInfo.headings} />
      <Table variant={'striped'} colorScheme={'teal'} mt={4}>
        <Thead>
          <Tr>
            {scenarioInfo.headings &&
              scenarioInfo.headings.map((header: any, index: number) => {
                return <Th key={index}>{header.label}</Th>;
              })}
          </Tr>
        </Thead>
        <Tbody>
          {scenarioInfo.data &&
            scenarioInfo.data.map((row: any, index: number) => {
              return (
                <Tr key={index}>
                  <Td fontWeight={'bold'}>{row.scenario_name}</Td>
                  <Td isNumeric>{row.route_length}</Td>
                  <Td isNumeric>{row.duration}</Td>
                  <Td isNumeric>{row.emissions}</Td>
                  <Td isNumeric>{row.fuel_consumption}</Td>
                  <Td isNumeric>{row.energy_consumption}</Td>
                  <Td isNumeric>{row.charge_stops}</Td>
                  <Td isNumeric>{row.vehicle_model}</Td>
                  <Td isNumeric>{row.drivetrain}</Td>
                  <Td isNumeric>{row.load}</Td>
                  <Td isNumeric>{row.energy_efficiency}</Td>

                  <Td isNumeric>{row.fuel_consumption_10km}</Td>
                  <Td isNumeric>{row.trips_per_month}</Td>
                  <Td isNumeric>{row.km_per_year}</Td>
                  <Td isNumeric>{row.total_maintenance}</Td>
                  <Td isNumeric>{row.write_off_yr} </Td>
                  <Td isNumeric>{row.insurance} </Td>
                  <Td isNumeric>{row.total_charge_cost} </Td>
                  <Td isNumeric>{row.total_fuel_cost} </Td>
                  <Td isNumeric fontWeight={'bold'}>
                    {row.total_annual_cost}
                  </Td>
                  <Td isNumeric>{row.cost_per_km} </Td>
                  <Td isNumeric fontWeight={'bold'}>
                    {row.annual_emissions}
                  </Td>
                  <Td isNumeric>{row.emissions_per_km} </Td>
                  <Td />
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </>
  );
}
