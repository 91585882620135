import { ModuleName } from './ModuleName';

interface ModuleIconsProps {
  modules: string[];
}
export function ModuleIcons(props: ModuleIconsProps) {
  const { modules } = props;

  return (
    <>
      {modules.map((module) => {
        return <ModuleName type={module} text='' key={module}></ModuleName>;
      })}
    </>
  );
}
