import { Text, useColorModeValue } from '@chakra-ui/react';

function SectionLabel(props: any) {
  return (
    <>
      <Text
        textTransform={'uppercase'}
        color={'teal.400'}
        fontWeight={600}
        fontSize={'sm'}
        bg={useColorModeValue('teal.50', 'teal.900')}
        p={2}
        alignSelf={'flex-start'}
        rounded={'md'}
        as='span'
        mb={3}
      >
        {props.children}
      </Text>
    </>
  );
}

export default SectionLabel;
