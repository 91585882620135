import { Table, TableCaption, TableContainer, Tbody, Td, Tr, Thead, Th } from '@chakra-ui/react';
import { SimulationResult } from '../../../../types/SimulationResult';
import { formatCapitalFirst, formatNumber } from '../../../../utils/formatters';

interface ResultSummaryProps {
  simulationResult: SimulationResult;
}

export default function ResultSummary(props: ResultSummaryProps) {
  const {
    energy_consumption_kWh_km,
    emissions_co2_g,
    fuel_consumption_unit,
    route_duration_s,
    route_length_m,
    vehicle_model,
    fuel_consumption_unit_10km,
    estimated_charges,
  } = props.simulationResult.result_summary;

  const { load_in_kg } = props.simulationResult.inputs;

  const vehicle = JSON.parse(props.simulationResult.inputs.vehicle);

  const isElectric = vehicle && vehicle.drivetrain.toLowerCase() === 'electric' ? true : false;
  const color = isElectric ? 'teal' : 'orange';
  const chargingsStyle = estimated_charges > 0 ? { backgroundColor: '#FEB2B2', fontWeight: 'bold', color: '#822727' } : {};

  return (
    <>
      <TableContainer>
        <Table variant='striped' colorScheme={color} mt={4}>
          <Thead>
            <Tr>
              <Th>Simulation summary:</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Route length:</Td>
              <Td isNumeric>{formatNumber(route_length_m / 1000)} km</Td>
            </Tr>
            <Tr>
              <Td>Duration:</Td>
              <Td isNumeric>{formatNumber(route_duration_s / 60)} min</Td>
            </Tr>
            <Tr>
              <Td>CO2 emissions:</Td>
              <Td isNumeric>{formatNumber(emissions_co2_g / 1000)} kg</Td>
            </Tr>
            {!isElectric && (
              <Tr>
                <Td>Fuel consumption:</Td>
                <Td isNumeric>{formatNumber(fuel_consumption_unit / 1000)} l</Td>
              </Tr>
            )}

            {isElectric && (
              <>
                <Tr>
                  <Td>Total energy consumption:</Td>
                  <Td isNumeric>{formatNumber((energy_consumption_kWh_km * route_length_m) / 1000)} kWh</Td>
                </Tr>
                <Tr>
                  <Td style={chargingsStyle}>Mid-trip charging stops:</Td>
                  <Td style={chargingsStyle} isNumeric>
                    {formatNumber(estimated_charges)}
                  </Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <TableContainer mt={5}>
        <Table variant='striped' colorScheme={color} mt={4}>
          <TableCaption>Results are experimental and should not be used without additional validation</TableCaption>
          <Thead>
            <Tr>
              <Th>Parameters:</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Vehicle model:</Td>
              <Td isNumeric>{vehicle_model}</Td>
            </Tr>
            <Tr>
              <Td>Drive:</Td>
              <Td isNumeric>{formatCapitalFirst(vehicle.drivetrain)}</Td>
            </Tr>
            <Tr>
              <Td>Load:</Td>
              <Td isNumeric>{formatNumber(load_in_kg)} kg</Td>
            </Tr>

            {isElectric && (
              <Tr>
                <Td>Energy efficiency:</Td>
                <Td isNumeric>{formatNumber(energy_consumption_kWh_km)} kWh / km</Td>
              </Tr>
            )}
            {!isElectric && (
              <Tr>
                <Td>Fuel efficiency:</Td>
                <Td isNumeric>{formatNumber(fuel_consumption_unit_10km / 1000)} l/10 km</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
