import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text, ListItem, UnorderedList } from '@chakra-ui/react';
import { ScenarioType } from '../../../types/ScenarioType';
import { ModuleName } from '../../layout/ModuleName';

export default function ExplainAccountingModules() {
  return (
    <>
      <Accordion allowMultiple mt={4} defaultIndex={[]}>
        <AccordionItem>
          <AccordionButton>
            <ModuleName type={ScenarioType.RoadTransport} text={'Road Transport Module'} />
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text fontWeight='bold'>Usage</Text>
            <Text>
              The road transport module allows you to simulate the operation of road going vehicles. This enables you to calculate and compare parameters such
              as:
            </Text>
            <UnorderedList ml='10'>
              <ListItem>Energy consumption</ListItem>
              <ListItem>CO2 emissions</ListItem>
              <ListItem>Charging need</ListItem>
              <ListItem>Energy cost</ListItem>
              <ListItem>Total Cost of Ownership</ListItem>
            </UnorderedList>
            <Text fontWeight='bold'>Technology</Text>
            <Text>It utilizes Seidr.AIs physics based model combined with AI to account for complex parameters such as elevation, traffic and weather.</Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <ModuleName type={ScenarioType.SeaTransport} text={'Sea Transport Module'} />
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text fontWeight='bold'>Usage</Text>
            <Text>
              The sea transport module allows you to simulate the operation of sea going vessels, with primary focus on freight, ferrys, fisheries and
              aquaculture. This enables you to calculate and compare parameters such as:
            </Text>
            <UnorderedList ml='10'>
              <ListItem>Energy consumption</ListItem>
              <ListItem>CO2 emissions</ListItem>
              <ListItem>Charging need</ListItem>
              <ListItem>Energy cost</ListItem>
              <ListItem>Total Cost of Ownership</ListItem>
            </UnorderedList>
            <Text fontWeight='bold'>Technology</Text>
            <Text>It utilizes Seidr.AIs physics based model combined with AI to account for complex parameters such as waves, curent and wind.</Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <ModuleName type={ScenarioType.Building} text={'Building Module'} />
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} backgroundColor='gray.050'>
            <Text>
              The building module will allow you to understand which measures you can take to reduce your CO2 emissions and how much it will cost. This will
              include typical measures such as insulation, changing windows, etc. But also energy production, energy control and interaction with charging of
              electric vehicles.
            </Text>
            <Text mt='4'>Calculate and compare key parameters such as:</Text>
            <UnorderedList ml='10'>
              <ListItem>Energy consumption</ListItem>
              <ListItem>CO2 emissions</ListItem>
              <ListItem>Investment need</ListItem>
              <ListItem>Change in operational cost</ListItem>
              <ListItem>ROI</ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <ModuleName type={ScenarioType.Solar} text={'Solar Module'} />
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} backgroundColor='gray.050'>
            <Text>The solar plant module lets you calculate the energy supplied to your project by a solar plant.</Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <ModuleName type={ScenarioType.ElectricityConsumer} text={'Electricity Consumer Module'} />
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} backgroundColor='gray.050'>
            <Text>
              This module lets you add any electricity consuming component or entity to your project. This could be a building, machine or any other
              installation where you already know the energy usage.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <ModuleName type={ScenarioType.ElectricitySupplier} text={'Electricity Supplier Module'} />
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} backgroundColor='gray.050'>
            <Text>
              This module lets you add any electricity producing component that will deliver to your project. This could be a power supplier, generator, power
              plant or similar where you already know the amount delivered to your project.
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}
