import { VStack, Image, Heading, Text, Button } from '@chakra-ui/react';

import achieved from '../../assets/achieved.png';

function Achievements() {
  return (
    <>
      <VStack>
        <Heading size={'md'}>Achieved reductions</Heading>
        <Image src={achieved} />
        <Text>Achieved reduction: 20 Tonnes</Text>
        <Text>Planned reduction: 200 Tonnes</Text>
        <Text>Cost: 693NOK/Tonnes</Text>
        <Button as='a' href='./insights'>
          More data insights
        </Button>
      </VStack>
    </>
  );
}

export default Achievements;
