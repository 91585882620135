import React, { useState } from 'react';
import { IconButton, useDisclosure } from '@chakra-ui/react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { sendFeedbackEmail } from '../../firestore/feedback';

export default function FloatingActionButtonWithForm() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  function sendMessage() {
    if (message) {
      sendFeedbackEmail(name, email, message);
      setEmail('');
      setName('');
      setMessage('');
    }
    onClose();
  }

  return (
    <>
      <IconButton colorScheme={'teal'} variant='outline' onClick={onOpen} aria-label='Provide feedback' icon={<EmailIcon />} isRound={true} />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Do you have feedback for us?</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel htmlFor='name'>Your name</FormLabel>
              <Input type='text' id='name' value={name} onChange={(e: any) => setName(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel htmlFor='email'>Your email</FormLabel>
              <Input type='email' id='email' value={email} onChange={(e: any) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel htmlFor='message'>What is it about?</FormLabel>
              <Textarea id='message' value={message} onChange={(e: any) => setMessage(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button colorScheme={'teal'} onClick={() => sendMessage()}>
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
