import AnnualGraph from '../../../../layout/graphs/AnnualGraph';
const fc_sample = require('d3fc-sample');

interface OutdoorTempGraphProps {
  time_series: any;
}

export default function OutdoorTempGraph(props: OutdoorTempGraphProps) {
  function outdoorTempData() {
    let data: any[] = [];

    for (var hourPoint in props.time_series.tempOutside) {
      data.push({ x: hourPoint, y: props.time_series.tempOutside[hourPoint] }); //hour number/temp
    }

    var sampler = fc_sample.modeMedian();
    sampler.bucketSize(24);

    let dailyTemps = sampler(data);
    let result: any[] = [];

    for (let step = 0; step < dailyTemps.length; step++) {
      //make sure step values are correct day not hour
      result.push({ x: step, y: dailyTemps[step].y });
    }

    return [
      {
        id: 'Temperature',
        color: 'hsl(325, 70%, 50%)',
        data: result,
      },
    ];
  }

  return (
    <AnnualGraph
      legendY={`℃`}
      legendX={'Day'}
      data={outdoorTempData()}
      heading={'Outdoor temperature'}
      description={'Outdoor temperature for a typical year at location'}
    />
  );
}
