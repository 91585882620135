import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logInWithEmailAndPassword } from '../../firestore/authentication';
import { auth } from '../../firestore/firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Input, VStack, Button, Link, Text, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import { Logo } from '../layout/Logo';
import LoginLayout from '../layout/LoginLayout';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [hasError, setError] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');

  async function login() {
    if (!email || !password) return;
    logInWithEmailAndPassword(email, password)
      .then()
      .catch((error) => {
        switch (error.code) {
          case 'auth/invalid-email':
            setErrorMessage('The email you have used is not valid. Check that the spelling is correct.');
            break;
          case 'auth/user-not-found':
            setErrorMessage("We don't seem to have an account for that username.");
            break;
          case 'auth/wrong-password':
            setErrorMessage('Wrong password - check your spelling and try again.');
            break;
          default:
            setErrorMessage('Somthing went wrong during login. Please check your usename and password. If the problem persists please contact us.');
            break;
        }
        setError(true);
      });
  }

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) navigate('/projects');
  }, [user, loading, navigate]);

  return (
    <LoginLayout>
      <VStack maxW='md'>
        <Logo h='40vmin' pointerEvents='none' />
        <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='E-mail Address' />
        <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' />
        <Button onClick={() => login()}>Login</Button>
        <Text>
          <Link href='/reset' color='teal.500'>
            Forgot Password
          </Link>
        </Text>
        <Text>
          Don't have an account?{' '}
          <Link href='/register' color='teal.500'>
            Register now.
          </Link>
        </Text>
        {hasError && (
          <Alert status='error'>
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
      </VStack>
    </LoginLayout>
  );
}
export default Login;
