import { Button, Box, useColorModeValue } from '@chakra-ui/react';
import { ModuleName } from '../../layout/ModuleName';

interface ComponentButtonProps {
  onClick: any;
  module: string;
  title: string;
  description: string;
}

export default function ComponentButton(props: ComponentButtonProps) {
  const { module, title, onClick, description } = props;

  return (
    <Box
      onClick={onClick}
      p={2}
      style={{
        borderBottom: '1px solid #dfdfdf',
        borderRight: '1px solid #dfdfdf',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
      }}
      backgroundColor={useColorModeValue('gray.50', 'gray.700')}
    >
      <ModuleName text={title} type={module} />
      <Box mt={2} style={{ display: 'flex', flexGrow: 1 }}>
        <Box fontSize={'xs'} mr={1}>
          {description}
        </Box>
        <Button colorScheme='teal' w={30} onClick={onClick} style={{ marginTop: 'auto', marginLeft: 'auto' }}>
          Go
        </Button>
      </Box>
    </Box>
  );
}
