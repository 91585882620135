import { Box, Heading, Text } from '@chakra-ui/react';
import { ResponsiveLine } from '@nivo/line';
import { GraphData } from '../../../types/TimeGraphData';
import { formatInteger, formatNumber } from '../../../utils/formatters';

interface AnnualGraphProps {
  legendX: string;
  legendY: string;
  data: GraphData;
  heading: string;
  description: string;
}

export default function AnnualGraph(props: AnnualGraphProps) {
  const { legendX, legendY, data, heading, description } = props;

  return (
    <Box h={400} mb={20} mt={10}>
      <Heading size={'md'}>{heading}</Heading>
      <Text>{description}</Text>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false,
        }}
        yFormat=' >-.2f'
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360],
          legend: legendX,
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: legendY,
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        enablePoints={false}
        enableArea={true}
        //areaBaselineValue={-70} should be min-x
        enableGridX={false}
        useMesh={true}
        crosshairType='x'
        tooltip={({ point }) => {
          return (
            <div
              style={{
                padding: '9px 12px',
                border: '1px solid #ccc',
                backgroundColor: 'rgba(255,255,255,.8)',
              }}
            >
              <div>
                {legendY}: {formatNumber(point.data.y)}
              </div>
              <div>
                {legendX}: {formatInteger(point.data.x)}
              </div>
            </div>
          );
        }}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 10,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
          },
        ]}
      />
    </Box>
  );
}
