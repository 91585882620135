import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getSimulationsSummarised } from '../../../firestore/simulations';
import { BreadCrumbInfo } from '../../../types/BreadCrumbInfo';
import { ScenarioType } from '../../../types/ScenarioType';
import { SimulationResult } from '../../../types/SimulationResult';
import OneColumnLayout from '../../layout/OneColLayout';
import ScenarioInfoRoad from './ScenarioInfoRoad';
import SimulationsList from './SimulationsList';

export default function CompareRoutes() {
  const { state } = useLocation();
  const { projectID, projectName } = state as any;

  const breadCrumbs: BreadCrumbInfo[] = [
    { to: '/projects', displayText: 'Projects' },
    { to: '/route-planning-overview', state: { projectID: projectID, projectName: projectName }, displayText: 'Route planning overview' },
  ];

  const [simulations, setSimulations] = useState<SimulationResult[]>([]);

  useEffect(() => {
    if (simulations.length === 0) {
      getSimulationsSummarised(projectID)?.then((result) => {
        if (result) {
          let simulations = _.sortBy(result as SimulationResult[], 'name');
          setSimulations(
            simulations.map((sim) => {
              return { ...sim, active: false };
            })
          );
        }
      });
    }
  }, [projectID, simulations]);

  function toggleScenario(scenario_id: string) {
    let scenario = simulations.find(({ id }) => id === scenario_id);
    if (scenario) {
      scenario.active = !scenario.active;
    }
    setSimulations([...simulations]); //react being an ass. make sure the actual reference is changing and not just the value
  }

  function roadScenarios(): SimulationResult[] {
    return simulations?.filter((sim) => sim.type === ScenarioType.RoadTransport);
  }

  return (
    <OneColumnLayout
      sectionLabel='Compare'
      sectionHeading={`Compare Results for ${projectName}`}
      sectionIntro={'Select the scenarios you would like see a detailed comparison of.'}
      breadCrumbs={breadCrumbs}
    >
      <>
        <Tabs variant='enclosed' colorScheme='teal'>
          <TabList>
            <Tab>Road transport scenarios</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SimulationsList onShowSimulation={toggleScenario} simulations={roadScenarios()} type={ScenarioType.RoadTransport}></SimulationsList>
              <ScenarioInfoRoad roadScenarios={roadScenarios()} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    </OneColumnLayout>
  );
}
