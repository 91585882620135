import { Box, Button, SimpleGrid, TabList, Tabs, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import CO2AccumulatedGraph from './components/graphs/CO2AccumulatedGraph';
import TotalPowerGraph from './components/graphs/TotalPowerGraph';
import { getTimeSeries, saveSimulation } from '../../../firestore/simulations';
import { useEffect, useState } from 'react';
import { SimulationResult } from '../../../types/SimulationResult';
import ResultTable from './components/ResultTable';
import RunParameters from './components/RunParameters';
import { BreadCrumbInfo } from '../../../types/BreadCrumbInfo';
import OneColumnLayout from '../../layout/OneColLayout';
import TCO from './components/TCO';

export default function ScenarioResult() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const simulation_result = state as SimulationResult;
  const { name, notes, project_id } = simulation_result;
  const { encoded_path } = simulation_result.inputs;

  const breadCrumbs: BreadCrumbInfo[] = [
    { to: '/projects', displayText: 'Projects' },
    { to: '/project', state: { projectID: project_id }, displayText: 'Project overview' },
    { to: '/sea-transport-scenario', state: { projectID: project_id }, displayText: 'Sea Scenario' },
  ];

  const [saving, setSaving] = useState<boolean>(false);
  const [TCOvalues, setTCO] = useState<any>();
  const [saveButtonText, setSaveButtonText] = useState<string>('Save Results');
  const [timeSeries, setTimeSeries] = useState<any>();

  useEffect(() => {
    const isSaved = !!simulation_result.id;

    if (isSaved) {
      setSaveButtonText('Save updated result');
      setSaving(false);
    }

    if (!timeSeries) {
      if (simulation_result.time_series) {
        setTimeSeries(simulation_result.time_series);
        return;
      } else if (simulation_result.id) {
        getTimeSeries(simulation_result.id)?.then((time_series) => {
          setTimeSeries(time_series);
        });
      }
    }
  }, [simulation_result, TCOvalues, timeSeries]);

  function save() {
    setSaving(true);
    setSaveButtonText('Saving...');
    saveSimulation({
      ...simulation_result,
      calculated_values: {
        energy_use_annual: TCOvalues.total_consumed_energy_kwh,
        co2_emissions_annual: TCOvalues.total_co2_emissions_ton,
        total_cost_of_ownership: TCOvalues,
      },
    }).then((res: any) => {
      setSaveButtonText(res.result === 'success' ? 'Results saved' : 'Results could not be saved');
    });
  }

  function goToNewRun() {
    navigate('/sea-transport-scenario', { state: { projectID: project_id } });
  }

  function goToNewRunSameRoute() {
    navigate('/sea-transport-scenario', { state: { projectID: project_id, encodedPath: encoded_path } });
  }

  const color = 'teal';

  return (
    <OneColumnLayout sectionLabel='Scenario Result' sectionHeading={`Scenario run results for ${name}`} sectionIntro={notes} breadCrumbs={breadCrumbs}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width='full' p={4}>
        <Button onClick={save} disabled={saving} colorScheme='teal'>
          {saveButtonText}
        </Button>
        <Button onClick={goToNewRun}>New Simulation</Button>
        <Button onClick={goToNewRunSameRoute} disabled={!encoded_path}>
          New Simulation with same route
        </Button>
      </SimpleGrid>
      <Tabs variant='enclosed' colorScheme={color}>
        <TabList>
          <Tab>Trip summary</Tab>
          <Tab>Trip graphs</Tab>
          <Tab>Total cost of ownership</Tab>
        </TabList>
        <TabPanels maxW={800}>
          <TabPanel>
            <ResultTable simulationResult={simulation_result} />
            <RunParameters simulationResult={simulation_result} />
          </TabPanel>
          <TabPanel>
            <Box>Total power usage over time: </Box>
            <TotalPowerGraph time_series={timeSeries} />
            <Box mt={4}>
              Accumulated CO<sub>2</sub> emissions over time:
            </Box>
            <CO2AccumulatedGraph time_series={timeSeries} />
          </TabPanel>
          <TabPanel>
            <TCO simulationResult={simulation_result} onUpdate={setTCO} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </OneColumnLayout>
  );
}
