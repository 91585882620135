import { Table, TableContainer, Tbody, Td, Tr, Thead, Th } from '@chakra-ui/react';
import { SimulationResult } from '../../../../types/SimulationResult';
import { formatNumber } from '../../../../utils/formatters';

interface ResultSummaryProps {
  simulationResult: SimulationResult;
}

export default function ResultSummary(props: ResultSummaryProps) {
  const { wh, co2_kg, tco_kr_kwh, investment_kr } = props.simulationResult.result_summary;

  return (
    <>
      <TableContainer>
        <Table variant='striped' colorScheme={'teal'} mt={4}>
          <Thead>
            <Tr>
              <Th>Simulation summary:</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Total annual energy production:</Td>
              <Td isNumeric>{formatNumber(wh / 1000)} kWh</Td>
            </Tr>
            <Tr>
              <Td>
                Annualised CO<sub>2</sub> emissions:
              </Td>
              <Td isNumeric>{formatNumber(co2_kg)} kg</Td>
            </Tr>
            <Tr>
              <Td>Total cost of ownership:</Td>
              <Td isNumeric>{formatNumber(tco_kr_kwh)} kr/kWh</Td>
            </Tr>
            <Tr>
              <Td>Investment cost (approximate):</Td>
              <Td isNumeric>{formatNumber(investment_kr)} kr</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
