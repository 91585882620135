import { Table, TableCaption, Tbody, Td, Tr, Th, Thead } from '@chakra-ui/react';
import { SimulationResult } from '../../../../types/SimulationResult';
import { formatNumber } from '../../../../utils/formatters';

interface ResultTableProps {
  simulationResult: SimulationResult;
}

export default function ResultTable(props: ResultTableProps) {
  const { fuel_consumption_unit, co2_emissions_kg, consumed_energy_kw, duration_min, energy_cost_kr } = props.simulationResult.result_summary;

  const { run_time_min } = props.simulationResult.result_summary.ship.engines[0];

  return (
    <Table variant='striped' colorScheme='teal' mt={4}>
      <TableCaption>Results are experimental and should not be used without additional validation</TableCaption>
      <Thead>
        <Tr>
          <Th>Simulation summary:</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>Duration:</Td>
          <Td isNumeric>{formatNumber(duration_min)} min</Td>
        </Tr>
        <Tr>
          <Td>Engine run time:</Td>
          <Td isNumeric>{formatNumber(run_time_min)} min</Td>
        </Tr>
        <Tr>
          <Td>Fuel consumption (MDO):</Td>
          <Td isNumeric>{formatNumber(fuel_consumption_unit)} l</Td>
        </Tr>
        <Tr>
          <Td>
            CO<sub>2</sub> emissions:
          </Td>
          <Td isNumeric>{formatNumber(co2_emissions_kg)} kg</Td>
        </Tr>
        <Tr>
          <Td>Consumed energy:</Td>
          <Td isNumeric>{formatNumber(consumed_energy_kw / 1000)} MWh</Td>
        </Tr>
        <Tr>
          <Td>Energy cost:</Td>
          <Td isNumeric>{formatNumber(energy_cost_kr)} kr</Td>
        </Tr>
        <Tr>
          <Td>Charged energy:</Td>
          <Td isNumeric>Coming soon!</Td>
        </Tr>
      </Tbody>
    </Table>
  );
}
