import { ChevronRightIcon } from '@chakra-ui/icons';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../firestore/firebaseConfig';
import { BreadCrumbInfo } from '../../types/BreadCrumbInfo';

interface BreadCrumbProps {
  links: BreadCrumbInfo[];
}

function Breadcrumbs(props: BreadCrumbProps) {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
  }, [user, loading, navigate]);

  return (
    <Breadcrumb
      spacing='8px'
      color={'teal.600'}
      textTransform={'uppercase'}
      fontWeight={600}
      fontSize={'sm'}
      alignSelf={'flex-start'}
      rounded={'md'}
      separator={<ChevronRightIcon color='gray.500' />}
      mt={2}
    >
      <BreadcrumbItem />
      {props.links.map((link: any) => (
        <BreadcrumbItem key={link.to}>
          <BreadcrumbLink as={Link} to={link.to} state={link.state} isCurrentPage={link.currentPage}>
            {link.displayText}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
      <BreadcrumbItem />
    </Breadcrumb>
  );
}

export default Breadcrumbs;
