import { Box } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import { DrawingManager, GoogleMap, Polyline, useLoadScript } from '@react-google-maps/api';
import { useEffect, useState } from 'react';

const googleMapsApiKey = 'AIzaSyCmpWpPIUgLejuVqKgXn-jub7gOQk-XBW8';
const midNorway = { lat: 60.843574, lng: 5.2469489 };
const zoom = 8;
const nautical_miles_factor = 1852;
const libraries: any = ['drawing', 'geometry']; //declare here as const to avoid re-renders

interface MapProps {
  onRouteChange: (length: number, encodedPath: string) => void;
  encodedPath?: string;
}
export default function Map(props: MapProps) {
  const { isLoaded } = useLoadScript({
    libraries,
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey,
  });

  const existingpath = props.encodedPath;
  const onRouteChange = props.onRouteChange;

  const [route, setBoatRoute] = useState<google.maps.Polyline>();

  useEffect(() => {
    if (isLoaded && existingpath) {
      let decodedPath = google.maps.geometry.encoding.decodePath(existingpath);
      let polylineLength = google.maps.geometry.spherical.computeLength(decodedPath) / nautical_miles_factor;
      onRouteChange(polylineLength, existingpath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]); //onRouteChange must not be included, it causes endless render

  function onPolyLineComplete(boatRoute: google.maps.Polyline) {
    var polylineLength = google.maps.geometry.spherical.computeLength(boatRoute.getPath()) / nautical_miles_factor;
    let encodedPath = google.maps.geometry.encoding.encodePath(boatRoute.getPath());
    props.onRouteChange(polylineLength, encodedPath);
    route?.setMap(null);
    setBoatRoute(boatRoute);
  }

  function drawPath() {
    if (existingpath) {
      let decodedPath = google.maps.geometry.encoding.decodePath(existingpath);
      return decodedPath;
    }
    return [];
  }

  return (
    <>
      {isLoaded ? (
        <GoogleMap center={midNorway} zoom={zoom}>
          <>
            <DrawingManager
              onPolylineComplete={onPolyLineComplete}
              drawingMode={google.maps.drawing.OverlayType.POLYLINE}
              options={{ drawingControl: false, polylineOptions: { draggable: true } }}
            />
            {existingpath && <Polyline options={{ path: drawPath() }} />}
          </>
        </GoogleMap>
      ) : (
        <Box>
          <Spinner size='xl' />
        </Box>
      )}
    </>
  );
}
