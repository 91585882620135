import { Box, ButtonGroup } from '@chakra-ui/react';
import { Formik } from 'formik';
import { CheckboxContainer, CheckboxControl, InputControl, SubmitButton } from 'formik-chakra-ui';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { createProject } from '../../../firestore/projects';
import { ProjectType } from '../../../types/ProjectType';
import { ScenarioType } from '../../../types/ScenarioType';

const initialValues = {
  name: '',
  modules: [],
};

const validationSchema = Yup.object({
  name: Yup.string().required('Poject name is required'),
  modules: Yup.array().min(1, 'At least one module must be selected'),
});

export default function AddRoutePlanningProject() {
  const navigate = useNavigate();

  const onSubmit = (values: any) => {
    createProject(values.name, values.modules, ProjectType.RoutePlanning).then((created: any) => {
      if (created.result === 'success') {
        navigate('/route-planning-scenario', {
          state: {
            projectName: values.name,
            projectID: created.projectID,
            modules: values.modules,
          },
        });
      } else {
        console.error('something went wrong when creating project', created.message);
      }
    });
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ handleSubmit, values, errors }) => (
          <Box borderWidth='1px' rounded='lg' shadow='1px 1px 3px rgba(0,0,0,0.3)' maxWidth={800} p={6} m='10px auto' as='form' onSubmit={handleSubmit as any}>
            <InputControl name='name' label='Project Name' />

            <CheckboxContainer name='modules' label='Modules' mt={4}>
              <CheckboxControl name='modules' value={ScenarioType.RoadTransport}>
                Road Transport
              </CheckboxControl>
              <CheckboxControl name='modules' value={ScenarioType.SeaTransport} isDisabled={true}>
                Sea Transport - coming soon
              </CheckboxControl>
            </CheckboxContainer>

            <ButtonGroup mt={4}>
              <SubmitButton>Set up project</SubmitButton>
            </ButtonGroup>
          </Box>
        )}
      </Formik>
    </>
  );
}
